<!-- 
  本订单页面 是特殊的，是从任务生成的
选地址  根据行政区域拿取 商品价格和商品运输方式（重算金额）

商品可使用积分 = 商品数量* 商品积分价（fPointPrice） || fEndBalance（用户积分余量 ）  这两个哪个值小用哪个。
0  <= 用户输入积分 <=  商品可使用积分
积分换算率  pointRMBPrice
积分抵扣金额  积分换算率 * 用户输入积分

优惠券使用哪个就是多少优惠券抵扣金额
优惠券选择 ，当商品价格（单价*数量）

选择配送方式为承运商后 开始算运费
自提和仓库代送 运费为 0 
用户选择是否用运输发票 来判断使用含税不含税的运费价格。
fFreightPrice（不含税）、fFreightPriceTax（含税）
fFreightPrice * fPlanAmount = 最终商品运费 
fNegotiateStatus   代表：2是议价完了；1是还在议价；0是还没议价
 -->
<template>
	<div :class="themeClass" class="min-width1100 max-width1100 margin-lr-auto" style="width: 1110px;margin-top: 20px;background-color: #FFFFFF;"
	 v-loading="laoding">
		<!-- 面包屑 -->
		<!-- <breadcrumb></breadcrumb> -->
		<!-- 面包屑END -->
		<div style="height: 111px;background: #FFFFFF;width: 100%;margin-top: 10px;" class="border-F2F2F2">
			<div style="padding: 15px 140px;">
				<el-steps :active="active" :align-center="true" finish-status="success">
					<el-step title="待支付订单"></el-step>
					<el-step title="填写订单"></el-step>
					<el-step title="支付订单"></el-step>
					<el-step title="支付结果"></el-step>
				</el-steps>
			</div>
		</div>
		<div class="" v-loading='orderLoading'>
			<div v-for="(item, index) in orderList" :key="index">
				<div class="font-size16 font-weight700 margin-b-10 margin-t-10 color-theme">
					订单编号：{{item.fOrderNumber}}
				</div>
				<div class="border-F2F2F2">
					<!-- 收货人信息 -->
					<div>
						<div class="font-size14 font-weight700 margin-b-10 margin-t-10 margin-lr-15 flex-row-space-between-center">收货人信息</div>
						<div v-if="JSON.stringify(item.address) != '{}'">
							<div class="price-box active border-color-theme flex-row-space-between-center pointer box-sizing" style="background: #f8f9fe;"
							 @mouseover="mouse = -99" @mouseout="mouse = -1">
								<div class="font-size14  flex-row-align-center">
									<div class=" flex-column-align-center margin-l-10 color-theme ">
										<div>{{ item.address.fLinkman }}</div>
										<div>{{ item.address.fLinkPhone }}</div>
									</div>
									<div class="margin-l-17 color-theme">工程名称：{{ item.address.fProjectName }}</div>
									<div class="margin-l-15 color-theme">收货地址：{{ item.address.fFullPathAddress }}</div>
								</div>
								<div class="padding-r-10 " v-show="mouse == -99" v-if='item.fNegotiateStatus == 2 ? false:true'>
									<i class="el-icon-edit font-color-666 font-size18 pointer" style="padding-right: 20px;" @click="EditAddress(item.address)"></i>
									<!-- <i class="el-icon-delete font-color-666 font-size18 pointer" @click="DeleteAddressChecked"></i> -->
								</div>
							</div>
							<!-- 上面这部分用来显示被选中的数据,下面的位其余数据 -->
							<div class="price-box active flex-row-space-between-center box-sizing pointer-border-color-theme border-F8F9FE"
							 v-for="(addressitem, addressindex) in item.noDefaultAddress" :key="addressindex" style="background: #f8f9fe;"
							 v-if="addressStatus && addressIndex == index" @mouseover="mouse = addressindex" @mouseout="mouse = -1">
								<div class="font-size14  flex-row-align-center font-color-999" @click="addressChoose(addressitem, index)">
									<div class=" flex-column-align-center margin-l-10 font-color-999">
										<div class="">{{ addressitem.fLinkman }}</div>
										<div class="">{{ addressitem.fLinkPhone }}</div>
									</div>
									<div class="margin-l-17 font-color-999">工程名称：{{ addressitem.fProjectName }}</div>
									<div class="margin-l-15 font-color-999">收货地址：{{ addressitem.fFullPathAddress }}</div>
								</div>
								<div class="padding-r-10" v-show="mouse == addressindex">
									<i class="el-icon-edit font-color-666 font-size18" style="padding-right: 20px;" @click="EditAddress(addressitem)"></i>
									<!-- <i class="el-icon-delete font-color-666 font-size18" @click="DeleteAddress(addressitem, addressindex)"></i> -->
								</div>
							</div>
							<div class="margin-lr-15 flex-row-align-center"  v-if='item.fNegotiateStatus == 2 ? false:true' >
								<div class="pointer margin-r-10" @click="noDefaultAddressShow(index)">
									{{ addressStatus && addressIndex == index ? '收起地址' : '展开地址' }}
									<i class=" " :class="addressStatus && addressIndex == index ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
								</div>
								<span class="pointer color-theme border-color-theme padding-lr-10 font-size13 padding-tb-5" @click="addAddress">+新增收货地址</span>
							</div>
						</div>
						<div v-else>
							<div class="price-box active flex-row-space-between-center box-sizing padding-lr-10 font-color-999" style="background: #f8f9fe;">
								暂无可用收货地址
							</div>
							<div class="padding-lr-13 margin-t-15 margin-b-15">
								<span class="pointer color-theme border-color-theme padding-lr-10 font-size13 padding-tb-5" @click="addAddress">+新增收货地址</span>
							</div>
						</div>
					</div> 
					<!-- 收货人信息结束 -->

          <!-- 定向送达活动 -->
          <div class="express" v-show="isShowExpress" >
            <div class="font-size14 font-weight700 margin-b-10 margin-t-10 margin-lr-15 tit">定向送达活动</div>
            <div class="btn" @click="showActivity(index)">点击参与 ></div>
            <el-table
              v-loading="loadingactivity"
              v-show="ishsowTable"
              :header-cell-style="{background:'#F8F9FE'}"
              class="margin-b-10 margin-t-10 margin-lr-15"
              :data="tableData[index]"
              size="mini"
              border
              style="width: 1070px">
              <el-table-column
                align="left"
                label="送达目的地"
                width="150">
                <template slot-scope="scope">
                  <el-button type="text" class="fence" @click="handleClick(scope.row)">{{scope.row.fFence}}</el-button>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="fDeliveryNames"
                label="提货方式"
                width="160">
              </el-table-column>
              <el-table-column
                align="center"
                label="运输时长(分钟)"
                width="200">
                <template slot-scope="scope">
                  <template v-if="scope.row.fMaxMins !=0">
                	  <span>{{scope.row.fMaxMins}}</span>
                	  	<hr>
                	  	<span :style="{color: '#FFAA00'}"><i class="el-icon-warning"></i> 出厂时间至抵达目的地的时长</span>
                	  </template>
                	  <template v-if="scope.row.fMaxMins ==0">
                	  	<span>不限时间</span>
                	  </template>		
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                label="活动时间">
                <template slot-scope="scope">
                  <span>{{scope.row.fBeginTime}}</span>
                <hr>
                <span>至</span>
                <hr>
                <span>{{scope.row.fEndTime}}</span>
                  <hr>
                  <span :style="{color: '#FFAA00'}"><i class="el-icon-warning"></i> 以送达时间为准</span>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="integral"
                label="赠送积分"
                width="120">
              </el-table-column>
			  <el-table-column
			    align="center"
			    prop="fIsHdName"
			    label="设备"
			    width="100">
			  </el-table-column>
              <el-table-column
              label=""
              width="160"
              align="center">
              <template slot-scope="scope">
                <el-button
                  @click.native.prevent="handleClick(scope.row)"
                  type="primary"
                  size="small">
                  查看目的地范围
                </el-button>
              </template>
            </el-table-column>
            </el-table>
          </div>

					<div class="font-size14 font-weight700 margin-b-10 margin-t-10 margin-l-15">商品信息</div>
					<div class=" margin-lr-15">
						<div class="flex-row-space-between-center font-size14" style="height: 35px;background: #f8f9fe;">
							<p class="width756" style="text-align: center;">商品信息</p>
							<p class="width156" style="text-align: center;">数量</p>
							<p class="width156" style="text-align: center;">单价</p>
						</div>
						<div class="wrap">
							<div class=" ">
								<div class="">
									<div class="flex-row-space-between-center" style="height: 123px;">
										<div class="flex-row-start-start">
											<div class="flex-row-space-between-center" style="margin-left: 15px;">
												<div class="box-shadow-all imageRadius" style="">
													<el-image class="imageRadius" :src="item.fPicturePath"></el-image>
												</div>
											</div>
											<div style="margin-left: 19px;">
												<div class="">
													<div class="tag-box font-size12 font-color-fff radius2 margin-r-5" style="display: inline-block;" v-if="item.fShopTypeID == 1">{{ item.fShopTypeName }}</div>
													{{ item.fGoodsFullName }}
												</div>
												<div class="flex-row" style="margin-top: 8px;">
													<div class="tag-box-red  font-size12 " v-if="item.text">{{ item.text }}</div>
													<div class="tag-box-blue color-theme border-theme font-size12" v-if="item.dei">{{ item.dei }}</div>
												</div>
												<!-- <div>工程：达坂城第一工程</div> -->
											</div>
										</div>
										<div class=" ">
											<div class="flex-row" >
												<div class="flexUD  width156" style="border-left: 1px solid #eaebef;height: 65px;">
													<el-input-number  disabled  size="small" v-model="item.fPlanAmount" :min="item.fMinSaleAmount" :max="99999" :step="item.fAdjustmentRange"
													 :precision="item.fPrecision" @change="valChange(item,index)" @blur="valBlur(item,index)" @focus="getInputFocus($event)"></el-input-number>
												</div>
												<div class="flexUD  width156" style="border-left: 1px solid #eaebef;height: 65px;">
													<p class="font-weight700">￥<span v-if='item.fGoodsMinPrice!="" &&item.fGoodsMinPrice == item.fGoodsPrice ? false:true'>{{item.fGoodsMinPrice}}~</span>{{item.fGoodsPrice}}</p>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="">
									<!--资金类型-->
									<div  class="padding-lr-15" style="padding-top: 6px;padding-bottom: 2px;">
										<el-row >
											<el-col :span="12">
												<div class="flex-row-align-center" style="min-width: 380px !important;  align-items: center;height:30px">
													<span style="line-height: 30px">资金类型：</span>
													  <el-radio-group v-model="item.fCapTypeID" @change="capTypeIDClick(item.fCapTypeID,index)">
														  <el-radio :label="item2.fCapTypeID"  v-for="(item2, index2) of item.fCapList" >
															  {{item2.fCapType}}
														  </el-radio>
													  </el-radio-group>
												</div>
											</el-col>
										</el-row>
									</div>
									<div class="margin-lr-15" style="padding-top: 6px;padding-bottom: 2px;">
										<el-row>
											<el-col :span="11">
												<div class="flex-row-align-center" style="min-width:380px !important; align-items: center;height:30px;line-height: 30px">
													<div style="display: flex;align-items: center;">
														<span style="min-width:70px">配送方式：</span>  
														<el-tooltip placement="right">
															<div slot="content">
																1、自提：由卖家在每月扎账后为您开具已完成出厂金额的货款发票。<br/>
																2、自营配送：由聚材在每月扎帐日后为您开具已完成出厂且配送的相应金额的货款发票。<br/>
																3、店铺配送：由第三方店铺为您开具相应已完成订单金额的货款发票。<br/>
																4、承运商代送：由聚材根据完成的出厂量按月为您开具相应的货款发票、提供物流服务的服务商开具运输发票。<br/>
																5、详细规则请参见《平台交易规则》、《物流服务协议》、《自营配送协议》。
															</div>
															<div class="form-label-mark flex-row-center-center margin-l-3">?</div>
														</el-tooltip>
													</div>
													<div style="display: flex;align-items: center;">
														<el-radio-group v-model="item.fDeliveryID" @change="deliveryMethodClick(item.fDeliveryID,index)" style="width:700px">
														  <el-radio :label="item2.fDeliveryID+''" :disabled='item.fNegotiateStatus == 2? true:false'  v-for="(item2, index2) of item.deliveryDtos" >
														  		{{item2.fDelivery}}
														  </el-radio>
														</el-radio-group>
													</div>
													 
												</div>
											</el-col>
											<el-col :span="7" v-if="item.fDeliveryID.toString() == '1'">
												<div class="flex-row-align-center">
													选承运商：
													<el-select  style="margin-left: 28px;"  :disabled='item.fNegotiateStatus == 2? true:false' v-model="item.carrierData" size="mini" @change="carrierClick(item.carrierData,index)">
														<el-option v-for="(item4, index4) in item.carrierDtos" :key="index4" :label="item4.fCustomerName" :value="item4.fCarrierID"></el-option>
													</el-select>
												</div>
											</el-col>
											<el-col :span="6" v-if="item.fDeliveryID == 1">
												
												<div  v-if='item.fNegotiateStatus == 2? false:true'    class="flex-row-align-center font-color-FF0000 font-weight700" style="float:right;color:black">
													运费单价:￥ <span v-if='item.fFreightPriceTax == 0 || item.carrierData == -1'>--</span><span v-else>{{item.fFreightPriceTax}}</span>  
												</div>
												<div  v-else  class="flex-row-align-center font-color-FF0000 font-weight700" style="float:right">
													运费单价:￥ <span v-if='item.fFreightPriceTax == 0 || item.carrierData == -1'>--</span><span v-else>{{item.ftFreightPrice}}</span>  
												</div>
											</el-col>
										</el-row>
									</div>
									<div class="margin-lr-15" style="padding-top: 6px;padding-bottom: 7px;">
										<el-row :gutter="0">
											<el-col :span="7">
												<div class="flex-row-align-center">
													运输方式：
													<el-select :disabled='item.fNegotiateStatus == 2? true:false'   v-model="item.fBusinessTypeID" size="mini" @change="BusinessTypeChoose(index)">
														<el-option v-for="(item7, index7) in item.BusinessType" :key="index7" :label="item7.fBuTypeName" :value="item7.fBusinessTypeID"></el-option>
													</el-select>
												</div>
											</el-col>
											<el-col :span="7" v-if="item.fDeliveryID.toString() == '1'">
												<div class="flex-row-align-center">
													开具运输发票：
													<el-select  :disabled='item.fNegotiateStatus == 2? true:false'  v-model="item.fIsNeedFInvoiceStatus" size="mini" @change="transportationInvoiceClick(item.fIsNeedFInvoiceStatus,index)">
														<el-option v-for="(item7, index7) in item.transportationInvoiceDtos" :key="index7" :label="item7.title"
														 :value="item7.id"></el-option>
													</el-select>
												</div>
											</el-col>
											<el-col :span="6" v-if="item.fDeliveryID == 1">
												<div class="flex-row-align-center font-color-999 font-size12" style="height: 30px;">
													<span class="iconfont icon-tishi5 font-color-FFAA00 margin-r-5"></span>
													运费单价,不含装卸等其他费用
												</div>
											</el-col>
										</el-row>
									</div>
									<div class="padding-lr-15 bg-F8F9FE margin-b-5">
										<el-row :gutter="0">
											<el-col :span="7">
												<div class="flex-row-align-center">
													<div style="width: 70px;">优惠券：</div>
													<el-select v-model="item.fCouponCustomerReceiveRecordID" size="mini" @change="couponClick(item.fCouponCustomerReceiveRecordID,index)"
													 :clearable="true" @clear="couponClear(item.fCouponCustomerReceiveRecordID,index)" :disabled="item.fIsEnableUseCoupon == 0 || (item.fCouponCustomerReceiveRecordID == '暂无可用' && item.couponList.length == 0)">
														<el-option v-for="(item5,index5) in item.couponList" :key="index5" :label="item5.fCouponCustomerReceiveRecordID!='-1'?'满' + item5.fMinSaleMoney + '减' + item5.fDeductionMoney:item5.fCouponName"
														 :value="item5.fCouponCustomerReceiveRecordID" :disabled="item5.isUse "></el-option>
													</el-select>
													<!-- <el-select v-model="item.fCouponCustomerReceiveRecordID" size="mini" @change="couponClick(item.fCouponCustomerReceiveRecordID,item.orderCoupon,index)">
														<el-option v-for="(item5,index5) in item.couponList" :key="index5" :label="item5.title" :value="item5.fCouponCustomerReceiveRecordID"></el-option>
													</el-select> -->
												</div>
											</el-col>
											<el-col :span="6">
												<div class="flex-row-align-center">
													使用积分：
													<input   disabled   class="inputStyle" style="number;margin-left: 27px;" v-model="item.fUsePoint" @blur="inputBlur(item,index)" @input="inputInput(item.fUsePoint,index)" />
												</div>
											</el-col>
											<el-col :span="10">
												<div class="flex-row-align-center font-color-999 font-size12" style="height: 30px;">
													<div class="font-size12 color-theme flex-row-space-between-center margin-r-10 ">
														<span class="iconfont icon-jifen1 color-theme"></span>
														- ¥{{item.fPointMoney | NumFormat }}
													</div>
													<span class="iconfont icon-tishi5 font-color-FFAA00 margin-r-10"></span>
													<!-- 您当前店铺积分余额为{{ originalPoint }},本商品最多可使用{{calculationEndBalance(item)}}积分 -->
												</div>
											</el-col>
										</el-row>
									</div>
									<!-- <div class="margin-lr-15 margin-t-10">
										<el-row :gutter="24">
											<el-col :span="8">
												<div class="flex-row-align-center">
													运输方式：
													<el-select v-model="item.fBusinessTypeID" size="mini" @change="BusinessTypeChoose(index)">
														<el-option v-for="(item7, index7) in item.BusinessType" :key="index7" :label="item7.fBuTypeName" :value="item7.fBusinessTypeID"></el-option>
													</el-select>
												</div>
											</el-col>
										</el-row>
									</div> -->
								</div>
								<div style="padding-top: 8px;" class="divcss5-3"></div>
								<div style="height: 134px;">
									<div class="flex-column-start-end font-size12 " style="margin-right: 15px;line-height: 22px;">
										<div class="font-color-999 flex-row-space-between-center" style="width: 180px;">
											货款金额：
											<div>
												<span
												v-if='item.fGoodsPrice == item.fGoodsMinPrice && item.deliveryDtos && item.deliveryDtos.length != 0 ?true:false'>
												+ ¥{{ (item.fGoodsPrice * 100 * item.fPlanAmount)/100 | NumFormat  }}
												</span>
												<span v-else>+ ¥{{item.fGoodsPrice * 0 | NumFormat}}</span>
											</div>
											
										</div>
										<div class="font-color-999 flex-row-space-between-center" style="width: 180px;">
											运费金额：
											<div v-if='needfNegotiateStatus == 2? false:true'>
												+ ¥<span v-if="item.fDeliveryID == 1">{{item.freightAmount | NumFormat  }}</span>
												<span v-if="item.fDeliveryID == 2">{{item.freightAmount*0 | NumFormat  }}</span> 
												<span v-if="item.fDeliveryID == 5">{{item.freightAmount*0 | NumFormat  }}</span> 
												</div>
											<div v-else>
												+ ¥<span v-if="item.fDeliveryID == 1">{{ffMoneyReceivable | NumFormat}}</span>
												<span v-if="item.fDeliveryID == 2">{{ffMoneyReceivable * 0 | NumFormat}}</span> 
												<span v-if="item.fDeliveryID == 5">{{item.freightAmount*0 | NumFormat  }}</span> 
											</div>
										</div>
										<div class="font-color-999 flex-row-space-between-center" style="width: 180px;">
											优惠券抵扣：
											<div>- ¥{{item.fCouponMoney | NumFormat  }}</div>
										</div>
										<div class="font-color-999 flex-row-space-between-center" style="width: 180px;">
											积分抵扣：
											<div>- ¥{{item.fPointMoney | NumFormat  }}</div>
										</div>
										<div class="flex-row-space-between-center" style="width: 180px;">
											<div>小计：</div>
											<div class="font-color-FF0000 font-weight700">¥<span v-if='item.fNegotiateStatus == 2?false:true'>{{ getOrderPrice(item)  | NumFormat}}</span> <span v-else>{{ specialPrice(item) +ffMoneyReceivable  | NumFormat}}</span> </div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- 发票信息 -->
			<div class="flex-row">
				<div class="font-size14 font-weight700 margin-b-10 margin-t-10 margin-r-10">发票信息</div>
				<div class="flex-row-align-center border-box">
					开具货款发票：
					<!-- <el-select v-model="fIsNeedInvoiceStatus" size="mini" @change="paymentForGoodsInvoiceClick(fIsNeedInvoiceStatus)">
						<el-option v-for="(item, index) in fIsNeedInvoiceStatusList" :key="index" :label="item.title" :value="item.id"></el-option>
					</el-select> -->
					<el-radio v-model="fIsNeedInvoiceStatus" :label="1">需要</el-radio>
					<el-radio v-model="fIsNeedInvoiceStatus" :label="0">不需要</el-radio>
				</div>
			</div>
			<div class="border-F2F2F2">
				<div class="flex-row-space-between-center color-theme padding-left-right-26 bg-fff padding-lr-5" style="background-color: #F2F5FF;height: 31px;border-radius: 1px;"
				 v-if="invoiceInformation.feMail!=null">
					<div class="flex-row-align-center">
						<i class="el-icon-s-ticket font-size18"></i>
						<p class="font-size14" style="padding-left: 6px;">发票信息</p>
						<!-- <p class="font-size14">(发送至{{invoiceInformation.feMail}})</p> -->
					</div>
					<i class="el-icon-arrow-up font-size18 pointer" :class="invoiceInformation.invoiceInformationTipsShow?'el-icon-arrow-up':'el-icon-arrow-down'"
					 @click="invoiceInformationTipsShowClick"></i>
				</div>
				<div v-else class="flex-row-space-between-center color-theme padding-left-right-26 bg-fff padding-lr-5" style="background-color: #F2F5FF;height: 31px;border-radius: 1px;">
					<div class="flex-row-align-center"><a class="font-size14 pointer hover" style="padding-left: 6px;" @click="addInvoice('Add')">+维护发票收件地址</a></div>
				</div>

				<div class="font-size12 font-color-666 bg-fff" style="padding: 10px 21px;" v-if="invoiceInformation.invoiceInformationTipsShow">
					<div class="margin-b-5">
						<div class="flex-row line-height22">
							<div>发票类型：</div>
							<div>{{invoiceInformation.fInvoiceClassName}}</div>
						</div>
						<div class="flex-row line-height22">
							<div>发票抬头：</div>
							<div>{{invoiceInformation.fUnitName}}</div>
						</div>
						<div class="flex-row" :class="getUserInfo.fCommerceTypeID=='1'?'margin-b-10 padding-b-10 border-bottom-F2F2F2':'line-height22'">
							<div>{{getUserInfo.fCommerceTypeID=='1'?'身份证号：':'企业税号：'}}</div>
							<div>{{invoiceInformation.fTaxAccounts}}</div>
						</div>
						<div v-if="getUserInfo.fCommerceTypeID=='2'">
							<div class="flex-row line-height22">
								<div>住所：</div>
								<div>{{invoiceInformation.fAddress}}</div>
							</div>
							<div class="flex-row line-height22">
								<div>公司电话：</div>
								<div>{{invoiceInformation.fPhone}}</div>
							</div>
							<div class="flex-row line-height22">
								<div>开户银行：</div>
								<div>{{invoiceInformation.fBankNames}}</div>
							</div>
							<div class="flex-row margin-b-10 padding-b-10 border-bottom-F2F2F2">
								<div>银行账号：</div>
								<div>{{invoiceInformation.fBankAccounts}}</div>
							</div>
						</div>
						<div class="flex-row-space-between">
							<div>
								<div class="flex-row">
									<div>邮寄地址：</div>
									<div>{{invoiceInformation.fFullPathAddress}}</div>
								</div>
								<div class="flex-row line-height22">
									<div>收件人：</div>
									<div>{{invoiceInformation.fLinkman}} {{invoiceInformation.fLinkPhone}}</div>
								</div>
								<div class="flex-row">
									<div>邮箱：</div>
									<div>{{invoiceInformation.feMail}}</div>
								</div>
							</div>
							<div class="flex-row-align-center pointer hover color-theme" @click="addInvoice('Edit')">
								修改发票收件地址
							</div>
						</div>

					</div>
				</div>
			</div>
			<!-- 发票信息结束 -->
			<div class="flex-row-right bottomBox font-size16" style="line-height: 30px;margin-top: 15px;margin-bottom: 50px;">
				<!-- <div class="flex-row-space-between-center font-size14 font-color-666"> -->
				<div class="flex-row-center-center" style="margin-right: 30px;">
					<div class="flex-row-align-center">
						<div class="flex-row">
							<p class="font-color-FF0000 ">{{ this.orderList.length }}</p>
							<p class="font-color-999">件商品</p>
							<p class="font-color-000">，实付金额：</p>
							<p class="font-color-FF0000 font-weight700">¥{{ getOrderTotal() | NumFormat }}</p>
						</div>

						<!-- <router-link to="/paymentOrder"> -->
						<!-- <router-link to="/shoppingCart/fillOrder/paymentOrder"> -->
						<el-button v-if='false'    style="width: 160px;height: 40px;margin: 15px;" class="background-color-theme pointer" :loading="createLoading"
						 @click="asktalkAboutPrice">运费议价</el-button>
						<el-button style="width: 160px;height: 40px;margin: 15px;" class="background-color-theme pointer" :loading="createLoading"
						 @click="updateOrder">提交订单</el-button>
						<!-- </router-link> -->
					</div>
				</div>
				<!-- </div> -->
			</div>
		</div>

		<!-- 收货地址 -->
		<el-dialog v-if="addVisible" v-dialogDrag :close-on-press-escape="false" :title="pageTitle + '收货地址'" :visible.sync="addVisible"
		 @close="handleClose" width="600px" :close-on-click-modal="false">
			<div ref="dialogBox" class="dialogScroll">
				<el-form ref="addressForm" :rules="rules" :model="addressForm" label-width="130px" label-position="right">
					<!-- <el-form-item label="地址类型">
						收货地址
					</el-form-item> -->
					<el-form-item label="收货人" prop="fLinkman">
						<el-input v-model="addressForm.fLinkman" placeholder="请输入收货人"></el-input>
					</el-form-item>
					<el-form-item label="手机号码" prop="fLinkPhone">
						<el-input v-model="addressForm.fLinkPhone" maxlength="11" placeholder="请输入手机号码"></el-input>
					</el-form-item>
					<el-form-item label="行政区域" class="areaBox" prop="area">
						<el-col :span="24">
							<selectArea v-model="addressForm.area"></selectArea>
						</el-col>
						<!-- <div class="mapBtn pointer font-size14 font-color-666" @click.once="getMyAddress">
							<span class="iconfont icon-dingwei margin-r-6"></span>获取当前地址
						</div> -->
					</el-form-item>
					<el-form-item label="详细地址" prop="fAddress">
						<el-input v-model="addressForm.fAddress" placeholder="请输入详细地址"></el-input>
					</el-form-item>
					<el-form-item label="工程名称" prop="fProjectName">
						<el-input v-model="addressForm.fProjectName" placeholder="请输入工程名称"></el-input>
					</el-form-item>
					<!-- <el-form-item v-if="addressForm.fAddressTypeID == 1 && addressAddIsHaveEmail == true" label="电子邮箱" :prop="addressForm.fAddressTypeID == 1?'feMail':''">
						<el-input v-model="addressForm.feMail" placeholder='请输入电子邮箱' />
					</el-form-item> -->
					<el-form-item label="设置默认地址" prop="addressType">
						<el-switch v-model="addressForm.fIsDefault"></el-switch>
					</el-form-item>
					<el-form-item>
						<div id="container" style="height: 200px;" v-show="mapShow"></div>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button size="small" @click="handleClose">取 消</el-button>
				<el-button size="small" type="primary" @click="submitForm('addressForm')">确 定</el-button>
			</span>
		</el-dialog>
		<!-- 维护发票信息 -->
		<el-dialog v-dialogDrag :close-on-press-escape="false" :title="invoiceType=='Add'?'维护':'编辑'+'发票收件地址'" :visible.sync="addInvoiceStatus"
		 width="600px" :before-close="addInvoiceClose" :close-on-click-modal="false" class="invoice">
			<div class=" " style="">
				<div class="font-size12 font-color-666 bg-F2F4FD" style="margin-bottom: 5px;">
					<div class="invoice-box" style="line-height: 22px;padding: 10px 15px;">
						<div class="font-size14 font-weight700 font-color-000">{{getUserInfo.fCommerceTypeID=='1'?'个人：':'企业：'}}发票信息</div>
						<div class="flex-row">
							<div style="width: 70px;">发票类型：</div>
							<div>{{invoiceInformation.fInvoiceClassName}}</div>
						</div>
						<div class="flex-row">
							<div style="width: 70px;">发票抬头：</div>
							<div>{{invoiceInformation.fUnitName}}</div>
						</div>
						<div class="flex-row">
							<div style="width: 70px;">{{getUserInfo.fCommerceTypeID=='1'?'身份证号：':'企业税号：'}}</div>
							<div>{{invoiceInformation.fTaxAccounts}}</div>
						</div>
						<div v-if="getUserInfo.fCommerceTypeID=='2'">
							<div class="flex-row">
								<div style="width: 80px;">住所：</div>{{invoiceInformation.fAddress}}
							</div>
							<div class="flex-row">
								<div style="width: 70px;">公司电话：</div>
								<div>{{invoiceInformation.fPhone}}</div>
							</div>
							<div class="flex-row">
								<div style="width: 70px;">开户银行：</div>
								<div>{{invoiceInformation.fBankNames}}</div>
							</div>
							<div class="flex-row">
								<div style="width: 70px;">银行账号：</div>
								<div>{{invoiceInformation.fBankAccounts}}</div>
							</div>
						</div>
						<!-- <div class="flex-row">
							<div style="width: 70px;">邮寄地址：</div>
							<div>新疆乌鲁木齐市新市区经济开发区卫星路 499号秦郡大厦B座501室</div>
						</div>
						<div class="flex-row">
							<div style="width: 70px;">收件人：</div>
							<div>王俊凯 16612345789</div>
						</div> -->
					</div>
				</div>
				<el-form ref="addInvoiceForm" :rules="rules" :model="addInvoiceForm" label-width="140px" label-position="right">
					<el-form-item label="收件邮箱" prop="feMail">
						<el-input v-model="addInvoiceForm.feMail" />
					</el-form-item>
					<el-form-item label="收件姓名" prop="fLinkman">
						<el-input v-model="addInvoiceForm.fLinkman" />
					</el-form-item>
					<el-form-item label="联系电话" prop="fLinkPhone">
						<el-input v-model.number="addInvoiceForm.fLinkPhone" maxlength="11" />
					</el-form-item>
					<el-form-item label="邮寄地址" prop="area">
						<div class="flex-row">
							<selectArea v-model="addInvoiceForm.area"></selectArea>
						</div>
					</el-form-item>
					<el-form-item label="详细地址" prop="fAddress">
						<el-input v-model="addInvoiceForm.fAddress" />
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button size="small" @click="addInvoiceStatus = false">取 消</el-button>
				<el-button size="small" type="primary" @click="invoiceSubmitForm('addInvoiceForm')">确 定</el-button>
			</span>
		</el-dialog>
		<selectBillPay  v-if='dialogVisible' :showModel="dialogVisible" @change="changeModel"></selectBillPay>

    <!-- 详细区域 -->
    <el-dialog
	  class="orderDialog"
      title="定向送达活动"
      :visible.sync="isShowActivityDailog"
      width="60%">
	  <div   class="margin-l-10" >
	   <span>定向送达活动中</span>
	   <span class="OrderText">使用GPS设备的车辆在抵达目的地后需上传车辆信息</span>
	   <span>，未上传信息的派车单将无法赠送积分(使用汉德设备无需上传)。</span>
	   <a style="color:#0173FE;OrderA" target="_blank" href="https://www.xjjcjc.com/helpcenter/FenceArrive.html">(点击查看GPS设备车辆上传操作流程)</a>
	  </div>
      <el-table
        :header-cell-style="{background:'#F8F9FE'}"
        :data="activitys"
        size="mini"
        border>
        <el-table-column
          label="选择"
          align="center"
          width="55">
          <template slot-scope="scope">
            <el-radio v-model="radioIdSelect" :label="scope.row.fRuleID" @change.native="handleSelectionChange(scope.row)" @click.native="clickRadio($event, scope.row)">{{str}}</el-radio>
          </template>
        </el-table-column>
        <el-table-column
          align="left"
          label="送达目的地"
          width="150">
          <template slot-scope="scope">
            <el-button type="text" class="fence" @click="handleClick(scope.row)">{{scope.row.fFence}}</el-button>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="fDeliveryNames"
          label="提货方式"
          width="160">
        </el-table-column>
        <el-table-column
          align="center"
          label="运输时长(分钟)"
          width="200">
         <template slot-scope="scope">
           <template v-if="scope.row.fMaxMins !=0">
         	  <span>{{scope.row.fMaxMins}}</span>
         	  	<hr>
         	  	<span :style="{color: '#FFAA00'}"><i class="el-icon-warning"></i> 出厂时间至抵达目的地的时长</span>
         	  </template>
         	  <template v-if="scope.row.fMaxMins ==0">
         	  	<span>不限时间</span>
         	  </template>		
         </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="活动时间">
          <template slot-scope="scope">
            <span>{{scope.row.fBeginTime}}</span>
                <hr>
                <span>至</span>
                <hr>
                <span>{{scope.row.fEndTime}}</span>
            <hr>
            <span :style="{color: '#FFAA00'}"><i class="el-icon-warning"></i> 以送达时间为准</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="integral"
          label="赠送积分"
          width="120">
        </el-table-column>
		<el-table-column
		  align="center"
		  prop="fIsHdName"
		  label="设备"
		  width="100">
		</el-table-column>
        <el-table-column
          label=""
          width="160"
          align="center">
          <template slot-scope="scope">
            <el-button
              @click.native.prevent="handleClick(scope.row)"
              type="primary"
              size="small">
              查看目的地范围
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="uncheckedActivity">取消选中</el-button>
        <el-button type="primary" @click="checkedActivity">确认选中</el-button>
      </span>
    </el-dialog>
   <myMapType v-if="myMapTypeVisible" :showModel="myMapTypeVisible" :myMapTypeID="myMapTypeID"  @change="myMapTypeChange"></myMapType>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	import breadcrumb from '@/components/breadcrumb.vue';
	import selectArea from '@/components/selectArea/selectArea.vue';
	import selectBillPay  from '../../selectBillPay.vue' // 票据协议的弹框
	import myMapType from '@/components/myMapType.vue';
	export default {
		components: {
			breadcrumb,
			selectArea,
			selectBillPay,
			myMapType
		},
		data() {
			const rulesloginId = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请输入手机号'));
				} else {
					let phoneNumberRul = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
					if (phoneNumberRul.test(value)) {
						callback();
					} else {
						callback(new Error('请输入正确的手机号码'));
					}
				}
			}
			return {
        // 定向送达活动励数据
        isShowActivityDailog: false,
        str: '',
        index: -1,
        num: 0,
        fUnitNames: [],
        loadingactivity: false,
        getexpress: [],
        radioIdSelects: [],
        fDeliveryIDs: [],
        radioIdSelect: '',
        tableData: [],
        activitys: [],
        tableDatas: [],
        isShowExpress: false,
        showFence: false,
        denceInfo: {},
        map: {},
        district: '',
        opts: {
          subdistrict: 0, // 获取边界不需要返回下级行政区
          extensions: 'all', // 返回行政区边界坐标组等具体信息
          level: 'district' // 查询行政级别为 市
        },
        showbiz: false,
        polygonData: [],
        radioActivity: {},
        ishsowTable: false,

				dialogVisible:false,//控制票据协议弹框的
				createLoading: false,
				isPrice:false,
				laoding: false,
				needfNegotiateStatus:'',
				ffMoneyReceivable:'',//新得到的运费
				id: '', //传递过来的订单id
				fFreightPrice: 0,
				orderIDList: [], // 上个页面拿取的订单ID数组
				orderList: [], //订单列表
				invoiceInformation: {}, //发票信息
				invoiceInformationTipsShow: false, // 发票信息展示

				fGovernmenAreaID: ['65', '6501', '650104', '650104008'],
				addInvoiceStatus: false,
				addInvoiceForm: {
					feMail: "", // 收件邮箱
					fLinkman: '', //收件姓名
					fLinkPhone: '', //手机号码
					fAddress: "", //详细地址
					area: ['', '', '', '']
				},
				invoiceType: '', //发票收件信息新增还是编辑
				mouse: -1,
				addVisible: false,
				mapShow: false, //地图
				maplat: '',
				maplng: '',
				addr: '新疆乌鲁木齐市新市区经济开发区卫星路499号秦郡大厦B座501室',
				pageTitle: "新增",
				addressForm: {
					fLinkman: '',
					fLinkPhone: '',
					area: ['', '', '', ''],
					fAddress: '',
					fProjectName: '',
					feMail: '',
					fAddressTypeID: '1',
					addressType: '收货地址',
					fIsDefault: false
				},
				rules: {
					fLinkman: [{
							required: true,
							message: '请输入收货人',
							trigger: 'blur'
						},
						{
							min: 2,
							max: 20,
							message: '长度在 2 到 20 个字符',
							trigger: 'blur'
						}
					],
					area: [{
						required: true,
						message: '请选择行政区域',
						trigger: 'blur'
					}],
					fLinkPhone: [{
							required: true,
							message: '请输入手机号码',
							trigger: 'change'
						},
						{
							validator: rulesloginId,
							trigger: 'blur'
						}
					],
					fAddress: [{
						required: true,
						message: '请填写详细地址',
						trigger: 'blur'
					}],
					feMail: [{
							required: true,
							message: '请填写邮箱地址',
							trigger: 'blur'
						},
						{
							type: 'email',
							message: '请填写正确的邮箱地址',
							trigger: ['blur', 'change']
						}
					],
					fProjectName: [{
						required: true,
						message: '请填写工程名称',
						trigger: 'blur'
					}]
				},

				initCouponList: [], // 优惠券初始列表（回填数据）
				useCouponList: [], //使用优惠券列表（修改数据）

				fIsNeedInvoiceStatus: 1, //是否开具货款发票

				addressStatus: false, //除了默认地址以外其他地址是否显示
				addressIndex: 0, //除了默认地址以外显示哪一个商品的其他地址

				addressTol: [], // 收货地址列表
				active: 2,

				// 收货地址是否维护 邮箱
				addressAddIsHaveEmail: false,

				// 优惠券 列表
				couponList: [],

				// 提交数组
				submitList: [],
				// 已提交下标数组
				submitListIndex: 0,
				// 提交成功数组
				submitSuccessList: [],
				//判断议价数据是否有成功的
				submitSuccessListPrice:[],
				needPriceIndex:0,
				//承运商议价所需数组
				submitListPrice:[],
				orderLoading:false,
				resultdata:[],
				fOrderTaskID:'',//订单任务id
				payPageShow:false,//当‘去议价’或者‘去结算’，为true
				paymentOrderoptions:{},
				myMapTypeVisible:false, //控制地图显示弹窗的显示与隐藏
				myMapTypeID:"",	
			};
		},
		async mounted() {
			console.log(this.$route.path,'****************')
			this.paymentOrderoptions.id = this.$route.query.id
			this.paymentOrderoptions.fOrderTaskID = this.$route.query.fOrderTaskID
			this.paymentOrderoptions.integralNumber = this.$route.query.integralNumber
			let getList = []
				getList.push({
					id:this.$route.query.id
				})
			this.orderIDList = getList;
			this.fOrderTaskID = this.$route.query.fOrderTaskID
			console.log(this.orderIDList,'冯典')
			// 查询用户已启用收货地址
			this.getOrderInformation();
			this.getAddressType();
			console.log(window.history.pushState,'什么什么！！！！')
			if (window.history && window.history.pushState) {
			  history.pushState(null, null, document.URL);
			  window.addEventListener('popstate', this.goBack, false);
			}
		},
		// async activated() {
		// 	this.orderIDList = await this.$store.getters.getCommonData;
		// 	// 查询用户已启用收货地址
		// 	this.getOrderInformation();
		// },
		computed: {
			...mapGetters(['getThemeName', 'getUserInfo']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
			calculationEndBalance() {
				return item => {
					return Math.floor(item.fPlanAmount * item.fPointPrice > item.fEndBalance ? item.fEndBalance : item.fPlanAmount * item.fPointPrice)
				}
			}
		},
		destroyed() {
		  //销毁返回监听
		  window.removeEventListener('popstate', this.goBack, false);
		  if(this.payPageShow === false){
			  let orderIds = [];
			  this.orderList.forEach((item, index) => {
			  	orderIds.push({fOrderID:item.fOrderID})
			  })
			  this.ApiRequestPostNOMess("api/mall/ebsale/order/delete-batch", 
			  	orderIds
			  	).then(res => {
			  		// console.log('把事情办了！！')
			  	}).catch(err => {
			  	})
		  }
		},
		methods: {
			goBack(){
				let orderIds = [];
				this.orderList.forEach((item, index) => {
					orderIds.push({fOrderID:item.fOrderID})
				})
				this.ApiRequestPostNOMess("api/mall/ebsale/order/delete-batch", 
					orderIds
					).then(res => {
						console.log('把事情办了！！')
						this.$router.go(-1)
					}).catch(err => {
					})
			},
			//票据弹框返回数据方法
			changeModel(e){
				this.dialogVisible = e
			},
			// 获取地址类型 判断收货地址是否显示 邮箱
			getAddressType() {
				// api/mall/ebbase/address-type/get-list
				this.ApiRequestPost('api/mall/ebbase/address-type/get-list', {}).then(
					res => {
						res.obj.items.forEach(item => {
							if (item.fAddressTypeID.toString() == "1") {
								if (item.fMustEmail.toString() == "0") {
									this.addressAddIsHaveEmail = false
								} else {
									this.addressAddIsHaveEmail = true
								}
							}
						})
					},
					rej => {}
				);
			},
			// 当前订单应支付金额
			getOrderPrice(item) {
				if(item.fGoodsPrice != item.fGoodsMinPrice){
					return 0
				}
				return (item.fGoodsPrice * 100 * item.fPlanAmount) / 100 - item.fPointMoney - item.fCouponMoney + item.freightAmount
			},
			//用在议价单的金额运算上
			specialPrice(item, index){
				if(item.fGoodsPrice != item.fGoodsMinPrice){
					return 0
				}
				return (item.fGoodsPrice * 100 * item.fPlanAmount) / 100 - item.fPointMoney - item.fCouponMoney 
			},
			// 订单总额
			getOrderTotal() {
				let total = 0;
				if(this.needfNegotiateStatus !=2){
					this.orderList.forEach(item => {
						total = total + this.getOrderPrice(item)
					})
				}else if(this.needfNegotiateStatus == 2){
					this.orderList.forEach(item => {
						total = total + this.specialPrice(item, "总额") + this.ffMoneyReceivable
					})
				}
				
				return total
			},
			//获取地址列表
			getProjectList() {
				this.ApiRequestPost('api/mall/eb-customer/address/get-enable-list-by-current-user-address', {
					addressTypeId: 1, //地址类型 1：收货地址
					maxResultCount: 99,
					skipCount: 0
				}).then(
					result => {
						result.obj.items.forEach((item, index) => {
							item.fFullPathAddress = item.fFullPathAddress.replace(/\\/g, '');
						});
						this.orderList.forEach((item, index) => {
							item.noDefaultAddress = result.obj.items;
							this.checkAddressInOrderLIst(item.fAddressID, index, item.noDefaultAddress)
						})
					},
					rej => {}
				);
			},
			// 检查默认地址中是否有订单地址
			checkAddressInOrderLIst(fAddressID, orderIndex, defaultAddress) {
				// 默认地址中是否有订单地址，
				let isHaveAddress = false
				for (let item of defaultAddress) {
					if (item.fAddressID == fAddressID) {
						isHaveAddress = true;
						break;
					}
				}
				// 如没有 将订单地址加入默认地址选择列表中
				if (isHaveAddress == false) {
					this.orderList[orderIndex].noDefaultAddress.unshift(this.orderList[orderIndex].address)
				}
			},
			addInvoiceClose() {
				this.addInvoiceStatus = false;
				this.addInvoiceForm = {};
			},
			addInvoice(type) {
				this.invoiceType = type;
				if (type == 'Edit') {
					this.getInvoiceAddress();
				}
				this.addInvoiceStatus = true;
			},
			//展开关闭其他地址
			noDefaultAddressShow(index) {
				this.addressIndex = index;
				this.addressStatus = !this.addressStatus;
			},
			//获取订单信息
			async getOrderInformation() {
				await this.ApiRequestPostNOMess('api/mall/ebsale/order/get-batch', this.orderIDList).then(
					res => {
						console.log(res,'---------------')
						let couponsDtos = [];
						this.orderList = [];
						this.initCouponList = [];
						this.useCouponList = [];
						res.obj.orders.forEach(data => {
							data.fDeliveryAddress = data.fDeliveryAddress.replace(/\\/g, '');
								// for(var dxdxval1 of data.carrierDtos){
								// 	if(dxdxval1.fCarrierID != data.fCarrierID){
								// 		data.fCarrierID = '-1'
								// 	}
								// }
							data.address = {
								fAddressID: data.fAddressID,
								fLinkman: data.fConsignee,
								fLinkPhone: data.fConsigneePhone,
								fFullPathAddress: data.fDeliveryAddress,
								fGovernmenAreaID: data.fGovernmenAreaID
							}
							// 积分抵扣金额
							// data.fPointMoney = 0;
							// 运费
							data.freightAmount = 0;
							data.carrierData = data.fCarrierID;
							data.carrierDtos = []; //加承运商数组
							data.transportationInvoiceDtos = [{
									title: '需要',
									id: 1
								},
								{
									title: '不需要',
									id: 0
								}
							];
							this.fIsNeedInvoiceStatus = data.fIsNeedInvoiceStatus;
							data.fFreightPriceTax = 0; //需要运输发票之后含税运费
							data.fMinSaleMoney = 0; //优惠券能使用的最小消费金额
							this.originalPoint = data.fUsePoint; //原始积分
							//由于该订单是  任务订单，所以可用积分是传值过来的
							data.couponsDtos = []; //优惠券信息
							data.fDeductionMoney = 0; //优惠券可抵扣金额
							data.isClick = 0; //优惠券是否手动触发不使用默认非手动触发0
							//鉴于该订单页面  属于任务订单创建而来，故：积分不可改，积分金额提前计算
							data.fUsePoint = this.$route.query.integralNumber;
							data.couponList = JSON.parse(JSON.stringify(res.obj.couponInfoAvailableDtos));
							if (res.obj.couponInfoAvailableDtos != null) {
								res.obj.couponInfoAvailableDtos.forEach((item) => {
									// 是否被使用
									item.isUse = false;
									// 那个订单在使用
									item.useOrder = null;
								});
								console.log('lalal11');
								data.couponList = JSON.parse(JSON.stringify(res.obj.couponInfoAvailableDtos));
								let findData = data.couponList.findIndex(findItem => findItem.fCouponCustomerReceiveRecordID == '-1')
								if (findData != -1) {
									data.couponList.splice(findData, 1)
								}
							} else {
								console.log('lalal22');
								data.couponList = [];
							}
							//当订单给出的承运商id并不在承运商数据里面时，需要做下处理
							this.orderList.push(data);
							
						})
						if (res.obj.couponInfoAvailableDtos != null) {
							console.log('lalal11');
							res.obj.couponInfoAvailableDtos.forEach((item) => {
								if (item.fCouponCustomerReceiveRecordID != '-1') {
									this.initCouponList.push(item);
									this.useCouponList.push(item);
									couponsDtos.push(item);
								}
							});
						}
						this.needfNegotiateStatus =  this.orderList[0].fNegotiateStatus
						this.ffMoneyReceivable = this.orderList[0].ffMoneyReceivable
						this.orderList.forEach((item, index) => {
							item.couponList.forEach(childItem => {
								if (item.fCouponCustomerReceiveRecordID == childItem.fCouponCustomerReceiveRecordID) {
									childItem.isUse = true;
									childItem.useOrder = index;
								}
							});
							couponsDtos.forEach(childItem => {
								if (item.fCouponCustomerReceiveRecordID == childItem.fCouponCustomerReceiveRecordID) {
									childItem.isUse = true;
									childItem.useOrder = index;
								}
							});
						});
						this.orderList.forEach((item) => {
							// item.couponList = JSON.parse(JSON.stringify(couponsDtos))
							if (this.useCouponList.findIndex(findItem => findItem.fCouponCustomerReceiveRecordID ==
									item.fCouponCustomerReceiveRecordID) != -1) {
								this.useCouponList.splice(this.useCouponList.findIndex(findItem => findItem.fCouponCustomerReceiveRecordID ==
									item.fCouponCustomerReceiveRecordID), 1);
							}
							console.log('this.useCouponList111', this.useCouponList);
						})
						this.orderList.forEach((item, index) => {
							item.couponList = item.couponList.concat(this.useCouponList);
							const hash = {};
							item.couponList = item.couponList.reduce((item, next) => {
								hash[next.fCouponCustomerReceiveRecordID] ? '' : hash[next.fCouponCustomerReceiveRecordID] = true && item
									.push(
										next);
								return item;
							}, [])
						});
						console.log('this.orderList', this.orderList,'***************');
						console.log('this.useCouponList', this.useCouponList);
						this.couponList = JSON.parse(JSON.stringify(couponsDtos));
						console.log('couponsDtos', this.couponList);
            for (var i = 0; i < res.obj.orders.length; i++) {
              this.radioIdSelects.push(-1)
              let obj1 = {}
              obj1.FGoods = res.obj.orders[i].fGoodsID
              obj1.FDepotID = res.obj.orders[i].fDepotID
              // obj1.fDeliveryIDs = this.fDeliveryIDs[i]

              this.getexpress.push(obj1)
              this.fUnitNames.push(res.obj.orders[i].fUnitName)
              // this.tableData[i] = [{
              //     activityTime: '',
              //     fDeliveryNames: '',
              //     fMaxMins: '',
              //     fFence: '',
              //     activeActivity: ''
              //   }]

            }
            console.log(this.getexpress)
						//获取地址
						this.getProjectList();
						//获取发票信息
						this.getInvoiceInfo();
						// 根据商品ID查询当前商品配送方式
						this.getDeliveryByAreaid();
						//用户可选择的资金类型
						this.acquireCapType();
						// 获取商品价格
						// this.getGoodsPrice();

						//获取商品图片
						this.getOrderPicture();
						// 获取用户积分
						this.getCustomerPoint();
						// 获取运输方式 批量
						this.getBusinessTypeBatch()
					},
					error => {}
				);
			},
			// 获取运输方式 批量订单
			async getBusinessTypeBatch() {
				this.laoding = true;
				let goodsIDList = []
				this.orderList.forEach((item, index) => {
					goodsIDList.push({
						goodsId: item.fGoodsID
					})
				});
				await this.ApiRequestPostNOMess('api/mall/ebsale/transport-business-type-rule/get-list-by-goods-id-batch',
						goodsIDList)
					.then(
						res => {
							res.obj.forEach((data, dataIndex) => {
								this.orderList.forEach((item, itemIndex) => {
									if (data.goodsid == item.fGoodsID) {
										// 查找汽运 并将其放置数组第一列
										let findData = data.list.find(findItem => findItem.fBusinessTypeID == "1")
										data.list.splice(data.list.indexOf(findData), 1);
										data.list.unshift(findData);
										item.BusinessType = data.list;
										item.fBusinessTypeID = '1';
									}
								})
							})
							this.$forceUpdate();
							this.laoding = false;
						},
						error => {}
					);
			},

			//获取用户积分
			async getCustomerPoint() {
				await this.ApiRequestPostNOMess('api/mall/ebbalance/customer-point/get-by-goods-id', {
						id: this.orderList[0].fGoodsID
					})
					.then(
						res => {
							this.originalPoint = res.obj.fEndBalance; //原始积分
							this.orderList.forEach((item, index) => {
								item.fEndBalance = res.obj.fEndBalance;
							});
							this.$forceUpdate(); //解决动态该数据之后渲染数据慢
						},
						error => {}
					);
			},

			// 根据商品ID查询当前商品配送方式
			async getDeliveryByAreaid(val) {
				await this.orderList.forEach((item, index) => {
					this.ApiRequestPostNOMess('api/mall/ebactivity/order-task/get-order-task-delivery', {
						 "fOrderTaskID": this.fOrderTaskID,
						})
						.then(
							res => {
								if (res.obj.length > 0) {
									// res.obj.unshift({
									// 	fDeliveryID: -1,
									// 	fDelivery: '请选择配送方式'
									// });
								}
								var changeItem =  res.obj.every((item1)=>{
									return  item1.fDeliveryID != item.fDeliveryID
									
								})
								if(changeItem == true){
									item.fDelivery = res.obj[0].fDelivery
									item.fDeliveryID = res.obj[0].fDeliveryID.toString()
								}
								console.log(item.fDeliveryID,'changeItem')
								
								item.deliveryDtos = res.obj;
                console.log(this.getexpress, index)
                this.getexpress[index].fDeliveryIDs = item.fDeliveryID
                this.getexpressInfo()
								// this.getGoodsPrice() 因为资金类型  会再次调用 商品价格的接口，所以注释掉这行冗余代码
								this.$forceUpdate(); //解决动态该数据之后渲染数据慢
							},
							error => {}
						);
				});
				this.getFreightPrice(); //承运商数据
			},
			//资金类型点击选中事件
			capTypeIDClick(cpid,index){
				console.log(cpid,'cpid')
				console.log(index,'index')
				this.orderList[index].fCapTypeID = cpid;
				this.getGoodsPrice()//点击更换  资金类型的时候再次调用商品价格的接口
				this.$forceUpdate(); //解决动态该数据之后渲染数据慢
			},
			//获取资金类型数据
			async acquireCapType(){
					await this.orderList.forEach((item, index) => {
						this.ApiRequestPostNOMess('api/mall/ebactivity/order-task/get-order-task-cap-type',{
							"fOrderTaskID": this.fOrderTaskID,
						})
							.then(
								res => {
									for(let item of  res.obj){
										item.fCapTypeID = Number(item.fCapTypeID)
									}
									var capItem =  res.obj.every((item1)=>{
										return  item1.fCapTypeID != item.fCapTypeID
										
									})
									if(capItem == true){
										console.log('不希望看到走这里！！！')
										item.fCapType = res.obj[0].fCapType
										item.fCapTypeID = res.obj[0].fCapTypeID
									}
									item.fCapList = res.obj;
									this.getGoodsPrice()
									this.$forceUpdate(); //解决动态该数据之后渲染数据慢
								},
								error => {}
							);
					});
			},

			//配送方式为承运商代送时候承运商数据
			async getFreightPrice() {
				let apiData = []
				this.orderList.forEach((item, index) => {
					apiData.push({
						fGovernmenAreaID: item.fGovernmenAreaID,
						fGoodsID: item.fGoodsID
					})
				});
				await this.ApiRequestPostNOMess('api/mall/ebsale/freight-price/get-b2c-freight-price-by-gov-and-goods-id-batch',
						apiData)
					.then(
						res => {
						res.obj.forEach((data, dataIndex) => {
								for (let item of this.orderList) {
									if (data.fGoodsID == item.fGoodsID) {
										let carrierDtosData = {
											fCarrierID: -1,
											fCustomerName: '请选择承运商'
										}
										if (data.freightPrice.length == 1 && data.freightPrice[0].fCarrierID == '0') {
											data.freightPrice = [carrierDtosData];
											item.carrierData = -1;
											item.fCarrierID = -1;
											item.carrierDtos = data.freightPrice;
											return;
										}
										item.carrierDtos = data.freightPrice;
										var falg = false;
													item.carrierDtos.forEach((val,index)=>{
														if (item.carrierData == val.fCarrierID) {
																falg = true;
														}
													});
													if(!falg){
																item.carrierData = -1;
															}
										item.carrierDtos.unshift(carrierDtosData);
										// 判断当前订单是否 选择开运输发票
										// 根据当前订单的承运商ID 获取 承运商数据
										if (item.fDeliveryID.toString() == "1") {
											let freightPriceData = data.freightPrice.find(p => p.fFreightPriceID == item.fFreightPriceID)
											if (item.fIsNeedFInvoiceStatus == 1) {
												if(freightPriceData !== undefined){
													item.freightAmount = freightPriceData.fFreightPriceTax * item.fPlanAmount;
												}
												
											} else {
												if(freightPriceData !== undefined){
													item.freightAmount = freightPriceData.fFreightPriceTax * item.fPlanAmount;
												}
												
											}
											if(freightPriceData !== undefined){
												item.fFreightPriceTax = freightPriceData.fFreightPriceTax
												item.fFreightPrice = freightPriceData.fFreightPrice
											}
											if(freightPriceData === undefined ){
												item.carrierData = -1;
												item.freightAmount = 0;
												item.fFreightPrice = 0;
												item.fFreightPriceTax = 0; //需要运输发票之后含税运费
											}
											
										} else {
											item.freightAmount = 0;
										}
										break;
									}
								}
						});
							this.$forceUpdate(); //解决动态该数据之后渲染数据慢
						},
						error => {}
					);
			},
			inputInput(val, index) {
				this.orderList[index].fUsePoint = val.toString().replace(/[^\d]/g, '');
				this.$forceUpdate(); //解决动态该数据之后渲染数据慢
			},
			//积分输入框失焦事件（算钱）
			inputBlur(orderData, i) {
				// if (this.orderList[0].fGoodsPrice * this.orderList[0].fPlanAmount - this.orderList[0].deductionMoney <
				// 	this.orderList[0].fMinSaleMoney) {
				// 	this.$message('当前选择优惠券暂不能使用');
				// 	// return;
				// }
				// //console.log('orderData.couponData', orderData.couponData);
				// if (orderData.fCouponCustomerReceiveRecordID != "-1") {
				// 	this.$message('请使用积分后在使用优惠券');
				// }
				// if (orderData.fUsePoint > this.originalPoint) {
				// 	this.$message('您当前只有' + this.originalPoint + '积分');
				// 	// return;
				// 	orderData.fUsePoint = orderData.fEndBalance;
				// }
				// if (orderData.fUsePoint > orderData.fPointPrice * orderData.fPlanAmount) {
				// 	this.$message('本商品最多使用' + orderData.fPointPrice * orderData.fPlanAmount + '积分');
				// 	// return;
				// 	orderData.fUsePoint = orderData.fPointPrice * orderData.fPlanAmount;
				// }
				// //console.log('orderData.fUsePoint', orderData.fUsePoint);
				// var surplusPoint = 0;
				// if (!orderData.fUsePoint) {
				// 	orderData.fUsePoint = 0;
				// }
				// let allPoint = 0; //机选所有订单使用了多少积分
				// if (this.orderList[0].fUsePoint != 0) {
				// 	allPoint += +this.orderList[0].fUsePoint;
				// }
				// surplusPoint = this.originalPoint - allPoint;
				// console.log('使用之后剩余积分', surplusPoint);

				// this.orderList[0].fEndBalance = surplusPoint;
				// this.orderList[0].couponsDtos.forEach((item, index) => {
				// 	if (item.fCouponCustomerReceiveRecordID == this.orderList[0].fCouponCustomerReceiveRecordID) {
				// 		item.checked = false;
				// 		this.orderList[0].deductionMoney = orderData.fUsePoint * 0.5; //积分抵扣多少钱
				// 		// console.log('积分抵扣多少钱1', this.orderList[0].deductionMoney);
				// 		this.orderList[0].fCouponCustomerReceiveRecordID = '-1';
				// 		this.orderList[0].fDeductionMoney = 0; //优惠券可抵扣金额
				// 	} else {
				// 		item.checked = false;
				// 		this.orderList[0].deductionMoney = orderData.fUsePoint * 0.5; //积分抵扣多少钱
				// 		// console.log('积分抵扣多少钱2', this.orderList[0].deductionMoney);
				// 	}
				// })

				// 商品可使用积分 = 商品价格（商品积分价 fPointPrice * 商品数量 fPlanAmount ）和用户积分余量 fEndBalance 那个值小用哪个
				let canUsePoints = (orderData.fPointPrice * orderData.fPlanAmount) > orderData.fEndBalance ? orderData.fEndBalance :
					(orderData.fPointPrice * orderData.fPlanAmount)
				// 当用户输入积分 不可大于 商品可使用积分
				if (orderData.fUsePoint > canUsePoints) {
					this.orderList[i].fUsePoint = canUsePoints;
				}
				this.orderList.forEach((item, index) => {
					if (index == i) {
						// 积分抵扣金额 = 用户输入积分 * （ 最大积分使用金额 / 积分价格 ）
						if (orderData.fUsePoint) {
							item.fPointMoney = parseInt(orderData.fUsePoint) * orderData.pointRMBPrice;
						} else {
							orderData.fUsePoint = 0;
							item.fPointMoney = parseInt(orderData.fUsePoint) * orderData.pointRMBPrice;
						}
					}
				});
				this.$forceUpdate(); //解决动态该数据之后渲染数据慢
			},

			datedifference(sDate1, sDate2) { //sDate1和sDate2是2006-12-18格式
				var dateSpan,
					tempDate,
					iDays;
				sDate1 = Date.parse(sDate1);
				sDate2 = Date.parse(sDate2);
				dateSpan = sDate2 - sDate1;
				dateSpan = Math.abs(dateSpan);
				iDays = Math.floor(dateSpan / (24 * 3600 * 1000));
				return iDays
			},
			//获取优惠券
			async getCouponInfo() {
				let orderIds = [];
				this.orderList.forEach((item, index) => {
					orderIds.push(item.fOrderID)
				})
				await this.ApiRequestPostNOMess('api/mall/ebactivity/coupon-info/get-goods-available-coupon', {
						fShopUnitID: this.orderList[0].fCompanyID,
						orderIds: orderIds
					})
					.then(
						res => {
							// let couponsDtos = [];
							// res.obj.forEach(async (couponItem, couponIndex) => {
							// 	if (couponItem.fReceiveStatus == 1) {
							// 		// 是否被使用
							// 		couponItem.isUse = false;
							// 		// 那个订单在使用
							// 		couponItem.useOrder = null;
							// 		await this.orderList.forEach((listItem, listIndex) => {
							// 			if (couponItem.fCouponCustomerReceiveRecordID == listItem.fCouponCustomerReceiveRecordID) {
							// 				if (listItem.fCouponCustomerReceiveRecordID == "-1" && listItem.fIsEnableUseCoupon == 0) {
							// 					// 优惠券ID
							// 					// listItem.fCouponCustomerReceiveRecordID = res.obj[res.obj.length - 1].fCouponCustomerReceiveRecordID;
							// 					listItem.fCouponCustomerReceiveRecordID = "-1";
							// 				} else if (listItem.fCouponCustomerReceiveRecordID == "-1" && listItem.fIsEnableUseCoupon == 1 &&
							// 					res.obj.length > 0) {
							// 					listItem.fCouponCustomerReceiveRecordID = res.obj.length - 1 + '张可用优惠券';
							// 				} else if (listItem.fCouponCustomerReceiveRecordID == "-1" && listItem.fIsEnableUseCoupon == 1 &&
							// 					res.obj.length == 0) {
							// 					listItem.fCouponCustomerReceiveRecordID = listItem.fCouponCustomerReceiveRecordID;
							// 				} else {
							// 					couponItem.useOrder = listIndex;
							// 					listItem.fCouponCustomerReceiveRecordID = listItem.fCouponCustomerReceiveRecordID;
							// 				}
							// 			}
							// 		})
							// 		couponsDtos.push(couponItem);
							// 	}
							// });
							// // 共通优惠券列表
							// this.couponList = couponsDtos;
							// this.$forceUpdate(); //解决动态该数据之后渲染数据慢
							this.initCouponList = [];
							this.useCouponList = [];
							res.obj.forEach((item, index) => {
								if (item.fCouponCustomerReceiveRecordID != '-1') {
									this.initCouponList.push(item);
									this.useCouponList.push(item);
								}
							})
							this.orderList.forEach((item, index) => {
								// item.fCouponCustomerReceiveRecordID = res.obj[res.obj.length - 1].fCouponCustomerReceiveRecordID
								if (item.fCouponCustomerReceiveRecordID == "-1") {
									item.couponList = [{
										title: '暂不使用',
										fCouponCustomerReceiveRecordID: '-1'
									}, {
										title: '使用',
										fCouponCustomerReceiveRecordID: ''
									}];
									this.$forceUpdate();
								} else {
									let findIndex = this.useCouponList.findIndex(findItem => findItem.fCouponCustomerReceiveRecordID ==
										item.fCouponCustomerReceiveRecordID);
									if (findIndex != -1) {
										console.log('findIndex', findIndex);
										console.log('item.fCouponCustomerReceiveRecordID', item.fCouponCustomerReceiveRecordID);
										console.log('this.useCouponList[findIndex].fCouponCustomerReceiveRecordID', this.useCouponList[findIndex].fCouponCustomerReceiveRecordID);
										if (item.fCouponCustomerReceiveRecordID == this.useCouponList[findIndex].fCouponCustomerReceiveRecordID) {
											item.couponList = [{
												title: '暂不使用',
												fCouponCustomerReceiveRecordID: '-1'
											}, {
												title: '使用',
												fCouponCustomerReceiveRecordID: item.fCouponCustomerReceiveRecordID
											}];
											item.orderCoupon = this.useCouponList[findIndex];
											item.fCouponMoney = this.useCouponList[findIndex].fDeductionMoney;
										}
										this.$forceUpdate();
										this.useCouponList.splice(findIndex, 1);
									}
								}
							})
						},
						error => {}
					);
			},
			//优惠券选择  选择的优惠券记录ID ， 订单ID
			// couponClick(id, val, index) {
			couponClick(fCouponCustomerReceiveRecordID, index) {
				console.log('this.orderList1212', this.orderList);
				if (fCouponCustomerReceiveRecordID) {
					this.orderList[index].couponList.forEach(item => {
						if (item.fCouponCustomerReceiveRecordID == fCouponCustomerReceiveRecordID) {
							item.isUse = true;
							item.useOrder = index;
						} else {
							// console.log('index', index);
							if (item.useOrder == index) {
								item.isUse = false;
								item.useOrder = null;
								this.useCouponList.push(item);
							}
						}
					});
					let fMinSaleMoney = null;
					this.couponList.forEach(item => {
						if (item.fCouponCustomerReceiveRecordID == fCouponCustomerReceiveRecordID) {
							console.log('1231', fCouponCustomerReceiveRecordID);
							fMinSaleMoney = item.fMinSaleMoney;
							item.isUse = true;
							item.useOrder = index;
						} else {
							console.log('index', index);
							if (item.useOrder == index) {
								item.isUse = false;
								item.useOrder = null;
								// this.useCouponList.push(item);
							}
						}
					})
					if (this.orderList[index].fGoodsPrice * this.orderList[index].fPlanAmount < fMinSaleMoney) {
						this.couponList.forEach(item => {
							if (item.useOrder == index) {
								item.isUse = false;
								item.useOrder = null;
								// this.useCouponList.push(item);
							}
						})
						this.orderList[index].couponList.forEach(item => {
							if (item.useOrder == index) {
								item.isUse = false;
								item.useOrder = null;
								this.useCouponList.push(item);
							}
						});
						this.orderList[index].fCouponCustomerReceiveRecordID = "暂不使用";
						this.orderList[index].fCouponMoney = 0; //优惠券可抵扣金额
						this.$message('此优惠券不满足优惠条件，请选择其他优惠券');
						return;
					}

					let id1 = "";
					let findData = this.orderList[index].couponList.find(findItem => findItem.fCouponCustomerReceiveRecordID ==
						fCouponCustomerReceiveRecordID)
					if (findData) {
						console.log('1241');
						// this.orderList[index].useCouponsDtos = [findData];
						id1 = findData.fCouponCustomerReceiveRecordID
					} else {
						this.orderList.forEach((item, index1) => {
							if (index != index1 && item.fIsEnableUseCoupon == 1) {
								item.couponList.push(findData)
							}
						})
					}
					this.orderList.forEach((item, index1) => {
						if (index != index1) {
							console.log('this.orderList[index1].couponList', this.orderList[index1].couponList);
							let findData = item.couponList.findIndex(findItem => findItem.fCouponCustomerReceiveRecordID == id1)
							console.log('findData', findData);
							if (findData != -1) {
								item.couponList.splice(findData, 1)
							}
						}
					})

					this.orderList.forEach((item, index1) => {
						if (item.couponList.length == 0) {
							item.fCouponCustomerReceiveRecordID = "暂无可用";
						} else {
							if (item.fCouponCustomerReceiveRecordID == "暂无可用") {
								item.fCouponCustomerReceiveRecordID = "暂不使用";
								item.fCouponMoney = 0; //优惠券可抵扣金额
							} else {
								item.fCouponCustomerReceiveRecordID = item.fCouponCustomerReceiveRecordID;
							}
						}
					})

					// 当前已选优惠券
					if (fCouponCustomerReceiveRecordID) {
						this.orderList[index].fCouponCustomerReceiveRecordID = fCouponCustomerReceiveRecordID;
						this.orderList[index].fCouponMoney = this.couponList.find(item => item.fCouponCustomerReceiveRecordID ==
							fCouponCustomerReceiveRecordID).fDeductionMoney; //优惠券可抵扣金额
					} else {
						console.log('aaaa', index);
						this.orderList[index].fCouponCustomerReceiveRecordID = '暂不使用';
						this.orderList[index].fCouponMoney = 0; //优惠券可抵扣金额
					}
				} else {
					console.log('123456');
					this.orderList[index].fCouponCustomerReceiveRecordID = '暂不使用';
					this.orderList[index].fCouponMoney = 0; //优惠券可抵扣金额
					this.orderList[index].couponList.forEach(item => {
						if (item.isUse == true && item.useOrder == index) {
							item.isUse = false;
							item.useOrder = null;
							this.useCouponList.push(item);
						}
					});
				}

				console.log('this.orderList111', this.orderList);
				console.log('this.useCouponList111', this.useCouponList);

				this.orderList.forEach((item) => {
					// item.couponList = JSON.parse(JSON.stringify(couponsDtos))
					// if (this.useCouponList.findIndex(findItem => findItem.fCouponCustomerReceiveRecordID ==
					// 		item.fCouponCustomerReceiveRecordID) != -1) {
					// 	this.useCouponList.splice(this.useCouponList.findIndex(findItem => findItem.fCouponCustomerReceiveRecordID ==
					// 		item.fCouponCustomerReceiveRecordID), 1);
					// }
					this.useCouponList = this.useCouponList.filter(filterItem => filterItem.fCouponCustomerReceiveRecordID !=
						item.fCouponCustomerReceiveRecordID)
					console.log('this.useCouponList123', this.useCouponList);
				})
				this.orderList.forEach((item) => {
					// item.couponList = JSON.parse(JSON.stringify(couponsDtos))
					item.couponList = item.couponList.concat(this.useCouponList);
					const hash = {};
					item.couponList = item.couponList.reduce((item, next) => {
						hash[next.fCouponCustomerReceiveRecordID] ? '' : hash[next.fCouponCustomerReceiveRecordID] = true && item.push(
							next);
						return item;
					}, [])
					console.log('item.couponList', item.couponList);
				})

				this.$forceUpdate(); //解决动态该数据之后渲染数据慢

				// console.log('id', id);
				// console.log('val', val);
				// if (id == '-1') {
				// 	this.orderList[index].isClick = 1;
				// 	this.orderList[index].fCouponMoney = 0;
				// 	let findData = this.useCouponList.find(findItem => findItem.fCouponCustomerReceiveRecordID == val.fCouponCustomerReceiveRecordID);
				// 	if (!findData) {
				// 		this.useCouponList.push(val);
				// 		this.$forceUpdate(); //解决动态该数据之后渲染数据慢
				// 	}
				// 	let findIndexList = [];
				// 	this.orderList.forEach((item, index1) => {
				// 		if (item.couponList.length == 1 && item.fIsEnableUseCoupon == 1) {
				// 			findIndexList.push(index1)
				// 		}
				// 		// if (item.couponList.length == 1) {
				// 		// 	item.couponList = [{
				// 		// 		title: '暂不使用',
				// 		// 		fCouponCustomerReceiveRecordID: '-1'
				// 		// 	}, {
				// 		// 		title: '使用',
				// 		// 		fCouponCustomerReceiveRecordID: ''
				// 		// 	}];
				// 		// }
				// 	})
				// 	if (this.useCouponList.length >= findIndexList.length) {
				// 		findIndexList.forEach((item) => {
				// 			this.orderList[item].couponList = [{
				// 				title: '暂不使用',
				// 				fCouponCustomerReceiveRecordID: '-1'
				// 			}, {
				// 				title: '使用',
				// 				fCouponCustomerReceiveRecordID: ''
				// 			}];
				// 			this.orderList[item].fCouponMoney = 0;
				// 		});
				// 	} else {
				// 		for (let i = this.useCouponList.length; i < findIndexList.length; i++) {
				// 			this.orderList[i].couponList = [{
				// 				title: '暂不使用',
				// 				fCouponCustomerReceiveRecordID: '-1'
				// 			}, {
				// 				title: '使用',
				// 				fCouponCustomerReceiveRecordID: ''
				// 			}];
				// 			this.orderList[i].fCouponMoney = 0;
				// 		}
				// 	}
				// 	// console.log('findIndexList', findIndexList);
				// } else {
				// 	for (var i = 0; i < this.useCouponList.length - 1; i++) {
				// 		//每轮比较次数，次数=长度-1-此时的轮数
				// 		for (var j = 0; j < this.useCouponList.length - 1 - i; j++) {
				// 			if (this.useCouponList[j].dateDifference > this.useCouponList[j + 1].dateDifference) {
				// 				var temp = this.useCouponList[j];
				// 				this.useCouponList[j] = this.useCouponList[j + 1];
				// 				this.useCouponList[j + 1] = temp;
				// 			} //end if
				// 		} //end for 次数
				// 	}
				// 	console.log('this.useCouponList', this.useCouponList);
				// 	let findData1 = this.useCouponList.find(findItem => findItem.fMinSaleMoney <= this.orderList[index].fGoodsPrice *
				// 		this.orderList[index].fPlanAmount)
				// 	console.log('findData1', findData1);
				// 	if (findData1) {
				// 		this.orderList[index].couponList = [{
				// 			title: '暂不使用',
				// 			fCouponCustomerReceiveRecordID: '-1'
				// 		}, {
				// 			title: '使用',
				// 			fCouponCustomerReceiveRecordID: findData1.fCouponCustomerReceiveRecordID
				// 		}];
				// 		this.orderList[index].fCouponCustomerReceiveRecordID = findData1.fCouponCustomerReceiveRecordID;
				// 		this.orderList[index].fCouponMoney = findData1.fDeductionMoney;
				// 		this.orderList[index].orderCoupon = findData1;
				// 		this.useCouponList.splice(this.useCouponList.findIndex(findItem => findItem.fCouponCustomerReceiveRecordID ==
				// 			findData1.fCouponCustomerReceiveRecordID), 1);
				// 		this.$forceUpdate(); //解决动态该数据之后渲染数据慢
				// 	}
				// 	// console.log('this.useCouponList.length', this.useCouponList.length);
				// 	let findIndexList1 = [];
				// 	if (this.useCouponList.length == 0) {
				// 		this.orderList.forEach((item, index1) => {
				// 			if (item.couponList.length == 2 && item.fIsEnableUseCoupon == 1 && item.fCouponCustomerReceiveRecordID == '-1') {
				// 				findIndexList1.push(index1)
				// 			}
				// 		})
				// 	}
				// 	// console.log('findIndexList1', findIndexList1);
				// 	findIndexList1.forEach((item) => {
				// 		this.orderList[item].couponList = [{
				// 			title: '暂不使用',
				// 			fCouponCustomerReceiveRecordID: '-1'
				// 		}];
				// 		this.orderList[item].fCouponMoney = 0;
				// 	});
				// }
				// console.log('this.useCouponList', this.useCouponList);
				// console.log('this.orderList[index]', this.orderList[index]);
			},

			// 优惠券清空事件
			couponClear(fCouponCustomerReceiveRecordID, index) {
				this.couponList.forEach(item => {
					if (item.fCouponCustomerReceiveRecordID == fCouponCustomerReceiveRecordID) {
						item.isUse = false;
						item.useOrder = null;
					}
				})
				// this.couponList.forEach(item => {
				// 	item.isUse = false;
				// 	item.useOrder = null;
				// })
				// // lastData 获取当前操作订单的优惠券最后一项
				// let lastData = this.couponList[this.couponList.length - 1]
				// this.orderList[index].fDeductionMoney = 0;
				// this.orderList[index].fCouponCustomerReceiveRecordID = lastData.fCouponCustomerReceiveRecordID;
			},
			//获取商品图片
			async getOrderPicture() {
				await this.orderList.forEach((data, index) => {
					this.ApiRequestPostNOMess('api/mall/goods/goods/get', {
						id: data.fGoodsID
					}).then(
						res => {
							this.orderList[index].fPicturePath = res.obj.fPicturePath;
							this.orderList[index].fShopTypeID = res.obj.fShopTypeID;
							this.orderList[index].fShopTypeName = res.obj.fShopTypeName;
							this.orderList[index].fStatus= res.obj.fStatus;//这句话是为了让this.orderList里面的fstatus来自商品自身
							this.$forceUpdate(); //解决动态该数据之后渲染数据慢
						},
						error => {}
					);
				})
			},
			//获取商品价格
			async getGoodsPrice() {
				let apiData = [];
				this.orderList.forEach((item, index) => {
					console.log('this.orderList', this.orderList);
					apiData.push({
						fGovernmenAreaID: item.fGovernmenAreaID, //不根据区域定价，区域id传-1
						goodsID: item.fGoodsID,
						deliveryId: item.fDeliveryID,
						capTypeID:item.fCapTypeID,//资金类型id(若为企业用户，那正常赋值；否则默认给1)
					})
				})
				await this.ApiRequestPostNOMess('api/mall/ebsale/goods-price/get-list-goods-price-by-government-area-id-batch',
						apiData)
					.then(
						res => {
							res.obj.forEach((item, index) => {
								this.orderList.forEach((childitem, childindex) => {
									if (item.fGoodsId == childitem.fGoodsID) {
										childitem.fGoodsPrice = item.fGoodsPrice; // 商品价格
										childitem.fGoodsMinPrice = item.fGoodsMinPrice;
										if(childitem.fGoodsPrice != childitem.fGoodsMinPrice || childitem.deliveryDtos.length == 0){
											this.$message('商品不在当前收货地址销售，请重新选择收货地址');
										}
										childitem.fPointPrice = item.fPointPrice; // 积分价格
										if(this.needfNegotiateStatus != 2){
											if(childitem.fDeliveryID == 1){
												this.isPrice = true
											}else if(childitem.fDeliveryID == 5 && childitem.carrierDtos.length >1){
												childitem.fGoodsMinPrice = Number(childitem.fGoodsMinPrice)  +  Number(childitem.carrierDtos[1].fFreightPriceTax)  + Number(childitem.carrierDtos[1].fMoney)
												childitem.fGoodsPrice =  Number(childitem.fGoodsPrice)  +  Number(childitem.carrierDtos[1].fFreightPriceTax)  + Number(childitem.carrierDtos[1].fMoney)
												childitem.fCarrierID  = childitem.carrierDtos[1].fCarrierID
												childitem.carrierData = childitem.carrierDtos[1].fCarrierID
											}else{
												this.isPrice = false
											}
											
										}else{
											    this.isPrice = false
										}
										childitem.fMaxPointMoney = item.fMaxPointMoney; // 商品最大可使用积分
										childitem.pointRMBPrice = item.pointRMBPrice; // 积分换算率
										childitem.fMinSaleAmount = item.fMinSaleAmount; // 最小销量
										childitem.fAdjustmentRange = item.fAdjustmentRange; // 销量调整幅度
										childitem.fPrecision = item.fPrecision; // 销量小数位数
										// fIsEnableUseCoupon 0无法使用 1可以使用优惠券
										childitem.fIsEnableUseCoupon = item.fIsEnableUseCoupon;
										console.log('childitem.fIsEnableUseCoupon', childitem.fIsEnableUseCoupon);
										childitem.fPointMoney = parseInt(childitem.fUsePoint) * childitem.pointRMBPrice;
									}
								});
							});
							console.log('this.orderList', this.orderList);

							let useIndex = null;
							this.orderList.forEach((item, index) => {
								if (this.orderList[index].couponList.length > 0 && item.fIsEnableUseCoupon == 1) {
									this.orderList[index].couponList.forEach((childitem, childindex) => {
										// FReceiveStatus 可以知道是否领取过，订单只显示领取过的优惠券
										//优惠券能使用的最小消费金额
										// this.orderList[index].fMinSaleMoney = childitem.fMinSaleMoney;
										// if (childitem.fCouponCustomerReceiveRecordID.toString() != '-1') {
										// item.couponList.push(childitem)
										if (item.fCouponCustomerReceiveRecordID == '-1') {
											item.fCouponCustomerReceiveRecordID = '暂不使用';
											item.fCouponMoney = 0;
										} else {
											if (item.fCouponCustomerReceiveRecordID == childitem.fCouponCustomerReceiveRecordID) {
												useIndex = index;
												console.log('index121212', index);
												if (useIndex != null) {
													let id1 = "";
													let findData = this.orderList[useIndex].couponList.find(findItem => findItem.fCouponCustomerReceiveRecordID ==
														this.orderList[useIndex].fCouponCustomerReceiveRecordID)
													console.log('findData', findData);
													if (findData) {
														// this.orderList[useIndex].useCouponsDtos = [findData];
														id1 = findData.fCouponCustomerReceiveRecordID
													} else {
														this.orderList.forEach((item, index1) => {
															if (useIndex != index1 && item.fIsEnableUseCoupon == 1) {
																item.couponList.push(findData)
															}
														})
													}
													this.orderList.forEach((item, index1) => {
														if (index1 != useIndex) {
															let findData = item.couponList.findIndex(findItem => findItem.fCouponCustomerReceiveRecordID ==
																id1)
															if (findData != -1) {
																item.couponList.splice(findData, 1)
															}
														}
													})
												}
												item.fCouponCustomerReceiveRecordID = childitem.fCouponCustomerReceiveRecordID;
												item.fCouponMoney = childitem.fDeductionMoney;
												// this.setDisabledByChooseCouponID(index, item.fCouponCustomerReceiveRecordID);
												// let findData = item.couponList.findIndex(findItem => findItem.fCouponCustomerReceiveRecordID == item
												// 	.fCouponCustomerReceiveRecordID)
												// console.log('findData', findData);
												// if (findData != -1) {
												// 	item.couponList[findData].checked = true;
												// 	console.log('item.couponList', item.couponList);
												// }
											}
										}
										if (item.couponList.length == 0) {
											item.fCouponCustomerReceiveRecordID = "暂无可用";
										}
										// } else if (item.couponList.length == 1 && res.obj[0].fCouponCustomerReceiveRecordID == '-1') {
										// 	item.couponData = "暂无可用";
										// 	item.fCouponCustomerReceiveRecordID = '-1';
										// 	item.couponList = [];
										// }
										this.$forceUpdate(); //解决动态该数据之后渲染数据慢
									});
								} else {
									item.fCouponCustomerReceiveRecordID = "暂无可用";
									item.couponList = [];
								}
								console.log('this.orderList232323', this.orderList);
							});

							this.$forceUpdate(); //解决动态该数据之后渲染数据慢
							// 获取商品优惠券
							// this.getCouponInfo();
						},
						error => {}
					);
			},

			//配送方式点击事件
			deliveryMethodClick(deliveryid, index) {
				console.log(this.orderList,'000000000000')
				this.getexpress[index].fDeliveryIDs = deliveryid;
        this.radioIdSelect = -1
        this.getexpressInfo()
				for(let item of this.orderList){
					//判断这个订单是否是议过价的订单
					if(this.needfNegotiateStatus !=2){
						if(item.fDeliveryID == 1){
							this.isPrice = true
							this.orderList[index].fCarrierID = -1;
							this.orderList[index].carrierData = -1;
						}else if(item.fDeliveryID == 5){
						console.log(this.orderList,'查看数组的数据')
						this.isPrice = false
						}else{
								this.isPrice = false
							}
					}else{
						this.isPrice = false
					}
				}
				// 配送方式变更时 是否运输发票 重置为 否
				this.orderList[index].fIsNeedFInvoiceStatus = 0;
				// 配送方式变更 不是承运商代送时 选择承运商重置为请选择  运价重置为0
				if (deliveryid != 1) {
					this.orderList[index].carrierData = -1;
					this.orderList[index].fCarrierID = -1;
					this.orderList[index].freightAmount = 0;
				}
				this.acquireCapType();
				// this.getGoodsPrice();//底下acquireCapType()获取资金类型数据，会再次调取getGoodsPrice()方法
				this.$forceUpdate(); //解决动态该数据之后渲染数据慢
			},

			//承运商点击事件
			carrierClick(fCarrierID, index) {
				this.fFreightPriceTax = 0;
				let fMinAmount = 0;
				let fFreightPriceTax = 0;
				this.orderList[index].carrierDtos.forEach(item => {
					console.log(fCarrierID,item.fCarrierID,'查看数据')
					// 选择承运商 拿取最小运载量
					if (fCarrierID == item.fCarrierID) {
						fMinAmount = item.fMinAmount;
						if(item.fFreightPriceTax){
							fFreightPriceTax = item.fFreightPriceTax
						}
					}
				});
				this.fFreightPriceTax = fFreightPriceTax;
				if (this.orderList[index].fPlanAmount < fMinAmount) {
					this.$message('当前承运商最小运输数量为' + fMinAmount);
					this.orderList[index].carrierData = -1;
					this.orderList[index].freightAmount = 0;
					return;
				}
				this.orderList[index].freightAmount = fFreightPriceTax * this.orderList[index].fPlanAmount;
				this.orderList[index].fCarrierID = fCarrierID;
				this.orderList[index].carrierData = fCarrierID;
				this.orderList[index].carrierDtos.forEach(item => {
					if (item.fCarrierID == fCarrierID) {
						this.orderList[index].fFreightPriceTax = item.fFreightPriceTax;
						this.orderList[index].fFreightPriceID = item.fFreightPriceID;
						this.orderList[index].fIsNeedFInvoiceStatus = 0;
					}
				})
				this.$forceUpdate(); //解决动态该数据之后渲染数据慢
			},

			//运输发票选择事件
			transportationInvoiceClick(fIsNeedFInvoiceStatus, index) {
				if (this.orderList[index].fCarrierID == -1) {
					this.orderList[index].fIsNeedFInvoiceStatus = 0;
					this.$forceUpdate(); //解决动态该数据之后渲染数据慢
					this.$message('请选择承运商');
				} else {
					this.orderList[index].fIsNeedFInvoiceStatus = fIsNeedFInvoiceStatus;
					if (fIsNeedFInvoiceStatus == 1) {
						this.orderList[index].freightAmount = this.orderList[index].fFreightPriceTax * this.orderList[index].fPlanAmount;
					} else {
						this.orderList[index].freightAmount = this.orderList[index].fFreightPriceTax * this.orderList[index].fPlanAmount;
					}
					this.$forceUpdate(); //解决动态该数据之后渲染数据慢
				}
			},

			//获取发票信息
			async getInvoiceInfo() {
				this.getInvoiceAddress();
				await this.ApiRequestPostNOMess('api/mall/ebcustomer/invoice-info/get', {}).then(
					res => {
						if (res.obj.fApproveStatus != 254) {
							this.tipsInfo('发票未审核通过')
						}
						this.invoiceInformation.fInvoiceClassName = res.obj.fInvoiceClassName;
						this.invoiceInformation.fUnitName = res.obj.fUnitName;
						this.invoiceInformation.fTaxAccounts = res.obj.fTaxAccounts;
						this.invoiceInformation.fPhone = res.obj.fPhone;
						this.invoiceInformation.fLinkman = res.obj.fLinkman;
						this.invoiceInformation.fAddress = res.obj.fAddress;
						this.invoiceInformation.fBankNames = res.obj.fBankNames;
						this.invoiceInformation.fBankAccounts = res.obj.fBankAccounts;
					},
					error => {}
				);
			},
			//获取发票地址
			async getInvoiceAddress() {
				await this.ApiRequestPostNOMess('api/mall/eb-customer/address/get-invoice-address', {}).then(
					res => {
						if (res.obj.feMail != null) {
							res.obj.area = ['', '', '', ''];
							res.obj.area[0] = res.obj.fGovernmenAreaID.substring(0, 2);
							res.obj.area[1] = res.obj.fGovernmenAreaID.substring(0, 4);
							res.obj.area[2] = res.obj.fGovernmenAreaID.substring(0, 6);
							res.obj.area[3] = res.obj.fGovernmenAreaID;
							this.addInvoiceForm = res.obj;
							this.invoiceInformation.feMail = res.obj.feMail;
							this.invoiceInformation.fLinkPhone = res.obj.fLinkPhone;
							this.invoiceInformation.fFullPathAddress = res.obj.fFullPathAddress.replace(/\\/g, '');
						}
					},
					error => {}
				);
			},
			//发票信息显示
			invoiceInformationTipsShowClick(index) {
				this.invoiceInformation.invoiceInformationTipsShow = !this.invoiceInformation.invoiceInformationTipsShow;
				this.$forceUpdate(); //解决动态该数据之后渲染数据慢
			},

			// 商品数量进步器 光标消失时调用
			valBlur(orderData, index) {
				// if (orderData.fPlanAmount == 0) {
				// 	return;
				// }
				// // 最小销量：fMinSaleAmount 销量调整幅度：fAdjustmentRange 销量小数位数：fPrecision
				// // 是否整除 isNumDivisible 
				// let isNumDivisible = orderData.fPlanAmount % orderData.fAdjustmentRange == 0 ? true : false
				// if (isNumDivisible == false) {
				// 	// 除后的值 valueAfterDivision    输入量 除以 调整幅度
				// 	let valueAfterDivision = (orderData.fPlanAmount * 100 / orderData.fAdjustmentRange * 100) / 100 / 100
				// 	// 更正后的值  除后的值四舍五入 乘以 调整幅度
				// 	this.orderList[index].fPlanAmount = valueAfterDivision.toFixed(0) * orderData.fAdjustmentRange
				// }
			},
			//数量加减
			valChange(orderData, index) {
				console.log('数量加减orderData', orderData);
				let fMinAmount = 0;
				let fFreightPrice = 0;
				this.orderList[index].carrierDtos.forEach(item => {
					if (orderData.carrierData == item.fCarrierID && item.fCarrierID != '-1') {
						fMinAmount = item.fMinAmount;
						if (orderData.fIsNeedFInvoiceStatus == 0) {
							this.orderList[index].freightAmount = item.fFreightPriceTax * orderData.fPlanAmount;
						} else {
							this.orderList[index].freightAmount = item.fFreightPriceTax * orderData.fPlanAmount;
						}

						this.$forceUpdate(); //解决动态该数据之后渲染数据慢
					}
				});
					if (this.orderList[index].fPlanAmount < fMinAmount && this.orderList[index].fDeliveryID != 5) {
						this.$message('当前承运商最小运输数量为' + fMinAmount + ',请重新选择承运商');
						this.orderList[index].carrierData = -1;
						this.orderList[index].fCarrierID = -1;
						this.orderList[index].freightAmount = 0;
						this.$forceUpdate(); //解决动态该数据之后渲染数据慢
						return;
					}
				let goodsPrice = this.orderList[index].fPlanAmount * this.orderList[index].fGoodsPrice;
				console.log('goodsPrice', goodsPrice);
				console.log('this.orderList[index].fIsEnableUseCoupon', this.orderList[index].fIsEnableUseCoupon);
				if (this.orderList[index].fIsEnableUseCoupon != 0) {
					console.log('this.couponList', this.couponList);
					for (let item of this.couponList) {
						// 当货款价格大于当前优惠券最小消费金额 且 当前可选
						if (goodsPrice < item.fMinSaleMoney && this.orderList[index].fCouponCustomerReceiveRecordID == item.fCouponCustomerReceiveRecordID) {
							this.orderList[index].fCouponCustomerReceiveRecordID = "暂不使用";
							this.orderList[index].fCouponMoney = 0;
							item.isUse = false;
							item.useOrder = null;
							this.useCouponList.push(item);
							break;
						}
					}
				}
				console.log('this.useCouponList', this.useCouponList);
				this.orderList.forEach((item,i) => {
					// item.couponList = JSON.parse(JSON.stringify(couponsDtos))
					item.couponList = item.couponList.concat(this.useCouponList);
					const hash = {};
					item.couponList = item.couponList.reduce((item, next) => {
						hash[next.fCouponCustomerReceiveRecordID] ? '' : hash[next.fCouponCustomerReceiveRecordID] = true && item.push(
							next);
						return item;
					}, [])
					console.log('item.couponList', item.couponList);
					if (index == i) {
						item.fUsePoint = this.calculationEndBalance(item); // 用户使用积分数默认最大积分
						if (orderData.fUsePoint) {
							item.fPointMoney = parseInt(orderData.fUsePoint) * orderData.pointRMBPrice;
						} else {
							orderData.fUsePoint = 0;
							item.fPointMoney = parseInt(orderData.fUsePoint) * orderData.pointRMBPrice;
						}
					}
				})
				this.orderList[index].couponList.forEach(item => {
					if (item.useOrder == index) {
						item.isUse = false;
						item.useOrder = null;
					}
				});
				this.$forceUpdate(); //解决动态该数据之后渲染数据慢

				// 编辑数量时，判断可用优惠券 自动选上
				// 货款价格
				// let goodsPrice = this.orderList[index].fPlanAmount * this.orderList[index].fGoodsPrice

				// let lastCoupon = this.couponList[this.couponList.length - 1]
				// if (this.orderList[index].fIsEnableUseCoupon != 0) {
				// 	for (let item of this.couponList) {
				// 		// 当货款价格大于当前优惠券最小消费金额 且 当前可选
				// 		if (goodsPrice > item.fMinSaleMoney) {
				// 			// this.orderList[index].fCouponCustomerReceiveRecordID = item.fCouponCustomerReceiveRecordID;
				// 			// this.orderList[index].fDeductionMoney = item.fDeductionMoney
				// 			item.isUse = true;
				// 			item.useOrder = index;
				// 			break;
				// 		} else if (goodsPrice < item.fMinSaleMoney && this.orderList[index].fCouponCustomerReceiveRecordID == item
				// 			.fCouponCustomerReceiveRecordID) {
				// 			item.isUse = false;
				// 			item.useOrder = null;
				// 			// this.orderList[index].fCouponCustomerReceiveRecordID = lastCoupon.fCouponCustomerReceiveRecordID;
				// 			// this.orderList[index].fDeductionMoney = lastCoupon.fDeductionMoney
				// 			break;
				// 		}
				// 	}
				// }

				// if (this.useCouponList.length > 0 && this.orderList[index].fIsEnableUseCoupon == 1 && this.orderList[index].isClick ==
				// 	0) {
				// 	console.log('this.useCouponList', this.useCouponList);
				// 	console.log('orderData', orderData);
				// 	for (var i = 0; i < this.useCouponList.length - 1; i++) {
				// 		//每轮比较次数，次数=长度-1-此时的轮数
				// 		for (var j = 0; j < this.useCouponList.length - 1 - i; j++) {
				// 			if (this.useCouponList[j].dateDifference > this.useCouponList[j + 1].dateDifference) {
				// 				var temp = this.useCouponList[j];
				// 				this.useCouponList[j] = this.useCouponList[j + 1];
				// 				this.useCouponList[j + 1] = temp;
				// 			} //end if
				// 		} //end for 次数
				// 	}
				// 	// console.log('this.useCouponList', this.useCouponList);
				// 	let findData1 = this.useCouponList.find(findItem => findItem.fMinSaleMoney <= this.orderList[index].fGoodsPrice *
				// 		this.orderList[index].fPlanAmount)
				// 	console.log('findData1', findData1);
				// 	if (findData1) {
				// 		this.orderList[index].couponList = [{
				// 			title: '暂不使用',
				// 			fCouponCustomerReceiveRecordID: '-1'
				// 		}, {
				// 			title: '使用',
				// 			fCouponCustomerReceiveRecordID: findData1.fCouponCustomerReceiveRecordID
				// 		}];
				// 		this.orderList[index].fCouponCustomerReceiveRecordID = findData1.fCouponCustomerReceiveRecordID;
				// 		this.orderList[index].fCouponMoney = findData1.fDeductionMoney;
				// 		this.orderList[index].orderCoupon = findData1;
				// 		this.useCouponList.splice(this.useCouponList.findIndex(findItem => findItem.fCouponCustomerReceiveRecordID ==
				// 			findData1.fCouponCustomerReceiveRecordID), 1);
				// 		console.log('this.useCouponList121212', this.useCouponList);
				// 		this.$forceUpdate(); //解决动态该数据之后渲染数据慢
				// 	}
				// 	let findIndexList1 = [];
				// 	if (this.useCouponList.length == 0) {
				// 		this.orderList.forEach((item, index1) => {
				// 			if (item.couponList.length == 2 && item.fIsEnableUseCoupon == 1 && item.fCouponCustomerReceiveRecordID == '-1') {
				// 				findIndexList1.push(index1)
				// 			}
				// 		})
				// 	}
				// 	// console.log('findIndexList1', findIndexList1);
				// 	findIndexList1.forEach((item) => {
				// 		this.orderList[item].couponList = [{
				// 			title: '暂不使用',
				// 			fCouponCustomerReceiveRecordID: '-1'
				// 		}];
				// 		this.orderList[item].fCouponMoney = 0;
				// 	});
				// }
			},

			//计数器获得焦点全选内容
			getInputFocus(event) {
				event.currentTarget.select();
			},

			// this.couponList.forEach(item => {
			// 	if (item.fCouponCustomerReceiveRecordID == fCouponCustomerReceiveRecordID) {
			// 		item.isUse = true;
			// 		item.useOrder = index;
			// 	} else {
			// 		if (item.useOrder == index) {
			// 			item.isUse = false;
			// 			item.useOrder = null;
			// 		}
			// 	}
			// })

			// 选择收货地址
			addressChoose(obj, activeindex) {
				// 页面上 收货人fConsignee、收货电话fConsigneePhone、收货地址fDeliveryAddress
				// 地址数据 收货人fLinkman、收货电话fLinkPhone、			收货地址fFullPathAddress
				console.log(this.orderList[activeindex].fGovernmenAreaID,'收货地址')
				console.log(obj.fGovernmenAreaID,'收货地址')
				if(this.orderList[activeindex].fGovernmenAreaID != obj.fGovernmenAreaID){
					var changeAddress = true
				}
				this.chooseAddress = obj;
				this.orderList[activeindex].address = obj;
				this.orderList[activeindex].fConsignee = obj.fLinkman;
				this.orderList[activeindex].fConsigneePhone = obj.fLinkPhone;
				this.orderList[activeindex].fDeliveryAddress = obj.fFullPathAddress;
				this.orderList[activeindex].fAddressID = obj.fAddressID;
				this.orderList[activeindex].fGovernmenAreaID = obj.fGovernmenAreaID;
				this.getDeliveryByAreaid(changeAddress);
				this.acquireCapType();
				// this.ApiRequestPostNOMess('api/mall/ebsale/goods-price/get-list-goods-current-price-by-address-id', {
				// 		addressId: this.orderList[activeindex].fAddressID,
				// 		goodsId: this.orderList[activeindex].fGoodsID,
				// 		deliveryId: this.orderList[activeindex].fDeliveryID
				// 	})
				// 	.then(
				// 		res => {
				// 			// 判断商品是否在销售
				// 			if (res.obj.length <= 0) {
				// 				this.$message('当前地址下商品不支持销售,请重新选择地址！');
				// 			} else {
				// 				res.obj.forEach((item1, index1) => {
				// 					if (item1.fIsEnableSale == 1) {
				// 						this.orderList.forEach((childitem, childindex) => {
				// 							if (item1.index == childindex) {
				// 								childitem.fGoodsPrice = item1.fGoodsPrice; // 商品价格
				// 								childitem.fPointPrice = item1.fPointPrice; // 积分价格
				// 								childitem.fMaxPointMoney = item1.fMaxPointMoney; // 商品最大可使用积分
				// 								childitem.pointRMBPrice = item1.pointRMBPrice; // 积分换算率
				// 								childitem.fMinSaleAmount = item1.fMinSaleAmount; // 最小销量
				// 								childitem.fAdjustmentRange = item1.fAdjustmentRange; // 销量调整幅度
				// 								childitem.fPrecision = item1.fPrecision; // 销量小数位数
				// 								// fIsEnableUseCoupon 0无法使用 1可以使用优惠券
				// 								childitem.fIsEnableUseCoupon = item1.fIsEnableUseCoupon;
				// 							}
				// 						});
				// 						this.getDeliveryByAreaid()
				// 					} else {
				// 						this.$message('当前地址下商品不支持销售,请重新选择地址！');
				// 					}
				// 				});
				// 			}
				// 		},
				// 		error => {}
				// 	);
				// this.$forceUpdate(); //解决动态该数据之后渲染数据慢
			},
			//议价的询问验证
			talkAboutPrice(){
				this.$confirm('是否确认该笔订单与承运商议价?', '提示', {
				          confirmButtonText: '确定',
				          cancelButtonText: '取消',
				          type: 'warning'
				        }).then(() => {
							this.speackPrice()
							this.payPageShow = true
				        }).catch(() => {       
				        });
			},
			//承运商议价
			asktalkAboutPrice(){
				let findData = this.orderList.find(findItem => JSON.stringify(findItem.address) == '{}')
				for (let item of this.orderList) {
					
									if (item.deliveryDtos.length >0) {
										for (let item1 of item.deliveryDtos) {
											if (item1.fDeliveryID == -1) {
												this.$message('商品不在当前收货地址销售，请重新选择收货地址');
												return;
											}
										}
									} else {
										this.$message('商品不在当前收货地址销售，请重新选择收货地址');
										return;
									}
									console.log(item)
									if(item.fDeliveryID != 1){
										this.$message('订单中存在不可以议价的订单，请确认订单详细情况');
										return;
									}
				
								}
				if (findData) {
					this.addAddress();
					return;
				}
				if (this.fIsNeedInvoiceStatus == 1 && this.invoiceInformation.feMail == null) {
					this.addInvoice('Add');
					return;
				}
				if (this.orderList.some(p => p.fDeliveryID == 1 && p.carrierData == -1)) {
					this.$message('请选择承运商');
					return;
				}
				if (this.orderList.some(p => p.fDeliveryID == -1)) {
					this.$message('请选择配送方式');
					return;
				}
					if (this.orderList.some(p => p.fCapTypeID == -1)) {
						this.$message('请选择资金类型');
						return;
					}
				if (this.orderList.some(p => p.fBusinessTypeID == 0 || p.fBusinessTypeID == '')) {
					this.$message('请选择运输方式');
					return;
				}
				// 提交参数
				this.submitListPrice = [];
				// 提交成功数组
				this.submitSuccessListPrice = [];
				this.orderList.forEach((item, index) => {
					if (item.fCouponCustomerReceiveRecordID == '暂不使用' || item.fCouponCustomerReceiveRecordID == '暂无可用') {
						item.fCouponCustomerReceiveRecordID = -1;
					}
					let params = {
						orderId:item.fOrderID,
						fGoodsID: item.fGoodsID,
						fAddressID: item.address.fAddressID,
						fUsePoint: item.fUsePoint,
						fDeliveryID: item.fDeliveryID, // 配送方式ID
						fCarrierID: item.fCarrierID, // 承运商ID
						fCapTypeID:item.fCapTypeID,//资金类型id()
						fCouponCustomerReceiveRecordID: item.fCouponCustomerReceiveRecordID == null ? -1 : item.fCouponCustomerReceiveRecordID, // 优惠券ID
						fIsNeedFInvoiceStatus: item.fIsNeedFInvoiceStatus, // 运输发票
						fIsNeedInvoiceStatus: this.fIsNeedInvoiceStatus, // 必须要发票
						fPlanAmount: item.fPlanAmount, // 商品数量
						fAppTypeID: this.fAppTypeID, // 应用类型ID
						fBusinessTypeID: item.fBusinessTypeID ,// 运输方式ID
						fcFreightPrice: item.fFreightPriceTax,
						fFreightPriceID: item.fFreightPriceID,
						frv:item.frv
					}
					this.submitListPrice.push(params);
				});
				this.talkAboutPrice();
			
			},
			//调用承运商议价的接口
			async speackPrice() {
				let forlength = this.submitListPrice.length;
				let forIndex = JSON.parse(JSON.stringify(this.needPriceIndex))
				this.orderLoading = true
				for (let index = forIndex; index < forlength; index++) {
					let item = this.submitListPrice[index]
          if (this.tableData[index].length === 0) {
              item.fRuleID = -1
            } else {
              item.fRuleID = this.tableData[index][0].fRuleID
            }
					// 异步调用API方法，以达到对成功的订单分段控制
          
					await this.ApiRequestPostNOMess('api/mall/ebsale/order/create-barganining-order-new', item)
						.then(
							res => {
								console.log(res)
                if (res.obj !== null) {
                  this.needPriceIndex = index + 1;
                  this.resultdata.push(res.obj)
                  if (this.needPriceIndex == this.submitListPrice.length) {
                    var colleteList = []
                    for(let item of this.resultdata){
                      colleteList.push({
                        fOrderNumber:item.fOrderNumber,
                        fCarrierName:item.fCarrierName,
                        fCarrierPhone:item.fCarrierPhone,
                      })
                    }
                    console.log(colleteList)
                    // 跳转到议价单页面
                    this.goToUnderReview(colleteList);
                  }
								} else {
                  let str = res.message
                  let arr = str.split(';')
                  console.log(arr)
                  if (arr[1] === '001' || arr[1] === '002' || arr[1] === '003') {
                    this.$confirm(createMessage + ', 是否继续?', '提示', {
                      confirmButtonText: '确定',
                      cancelButtonText: '取消',
                      type: 'warning'
                    }).then(() => {
                      if (arr[1] == '001') {
                        this.$store.dispatch("setShowAttestation", true);
                      } else if (arr[1] == '002') {
                        this.addInvoice('Add');
                      } else if (arr[1] == '003') {
                        if (this.getUserInfo.fCommerceTypeID == '1') {
                          this.$router.push({
                            path: '/businessme/individualUsersBindingCard',
                            query: {
                              title: '开通账服通',
                              type: 2,
                              fAccountname: '账服通'
                            }
                          }) //跳转个人用户绑卡
                        } else if (this.getUserInfo.fCommerceTypeID == '2') {
                          this.$router.push({
                            path: '/businessme/enterpriseUsersBindingCard',
                            query: {
                              title: '开通账服通',
                              type: 2,
                              fAccountname: '账服通'
                            }
                          }) //跳转企业用户绑卡
                        }
                      }
                    })
                  } else {
                    this.$message(arr[0])
                  }
                  // if (arr[arr.length-1] === '001')
                  this.orderLoading = false
                }
								
								
							},
							error => {
                this.$message.error(error)
                this.orderLoading = false
							}
						);
				}
			},
			//跳转审核中页面
			goToUnderReview(val) {
				console.log('议价审核中');
				// this.$router.push("/about/Allperfect");
				this.orderLoading = false
				this.$router.push({
					name: "bargainPrice",
					params:{fname: JSON.stringify(val)},
				});
			},

			//结算创建订单
			updateOrder() {
				if(this.getOrderTotal() <0){
					this.$message('亲，您的订单金额有问题，请联系商城客服!');
					return
				}
				//在创建订单前判断商品是否可以进行购买
				var falg = false;
					this.orderList.forEach((val,index)=>{
						if (val.fStatus == 2) {
							falg = true;
						}
					});
					if(falg == true){
						 this.$message('亲，当前商品已售罄，无法购买');
						 return
					}
				//在创建订单前判断商品是否可以进行购买；如果是区间价则不可以继续创建订单
				var falgcontinue = false;
				this.orderList.forEach((val,index)=>{
					if (val.fGoodsPrice != val.fGoodsMinPrice) {
						falgcontinue = true;
					}
				});
				if(falgcontinue == true){
					 this.$message('商品不在当前收货地址销售，请重新选择收货地址！');
					 return
				}
				
				let findData = this.orderList.find(findItem => JSON.stringify(findItem.address) == '{}')
				if (findData) {
					// this.$message('请维护地址或者选择地址！');
					this.addAddress();
					return;
				}
				if (this.fIsNeedInvoiceStatus == 1 && this.invoiceInformation.feMail == null) {
					// this.$message('请维护发票收件地址');
					this.addInvoice('Add');
					return;
				}
				if (this.orderList.some(p => p.fDeliveryID == 1 && p.carrierData == -1)) {
					this.$message('请选择承运商');
					return;
				}
				if (this.orderList.some(p => p.fDeliveryID == -1)) {
					this.$message('请选择配送方式');
					return;
				}
					if (this.orderList.some(p => p.fCapTypeID == -1)) {
						this.$message('请选择资金类型');
						return;
					}
				if (this.orderList.some(p => p.fBusinessTypeID == 0 || p.fBusinessTypeID == '')) {
					this.$message('请选择运输方式');
					return;
				}
				if(this.orderList[0].fCapTypeID == 2){
					this.ApiRequestPostNOMess('api/mall/ebbalance/customer-acceptance/get-order-my-acceptance', {}).then(
						res => {
							//res.obj 有值证明人家开通着，否则证明根本没开通
							if(res.obj.length !=0){
								this.dialogVisible = false
								// 提交参数
								this.submitList = [];
								// 提交成功数组
								this.submitSuccessList = [];
								this.orderList.forEach((item, index) => {
									if (item.fCouponCustomerReceiveRecordID == '暂不使用' || item.fCouponCustomerReceiveRecordID == '暂无可用') {
										item.fCouponCustomerReceiveRecordID = -1;
									}
									let params = {
										orderId: item.fOrderID,
										fBusinessTypeID: item.fBusinessTypeID,
										fGoodsID: item.fGoodsID,
										fAddressID: item.fAddressID,
										fUsePoint: item.fUsePoint,
										fDeliveryID: item.fDeliveryID,
										fCarrierID: item.fCarrierID,
										fCapTypeID:item.fCapTypeID,//资金类型id()
										fCouponCustomerReceiveRecordID: item.fCouponCustomerReceiveRecordID,
										fIsNeedFInvoiceStatus: item.fIsNeedFInvoiceStatus,
										fIsNeedInvoiceStatus: this.fIsNeedInvoiceStatus,
										fPlanAmount: item.fPlanAmount,
										fAppTypeID: this.fAppTypeID,
										frv:item.frv
									}
                  if (this.tableData[index].length === 0) {
                    params.fRuleID = -1
                  } else {
                    params.fRuleID = this.tableData[index][0].fRuleID
                  }
									this.submitList.push(params);
								});
								console.log('this.orderIDList', this.orderIDList);
								this.createLoading = true;
								this.ApiRequestPutNOMess('api/mall/ebsale/order/update-b2c-new-batch', this.submitList)
									.then(
										res => {
											this.createLoading = false;
											if (res.message == "") {
												let paramsData = [];
												let orderIds = [];
												for (let item of this.orderIDList) {
													paramsData.push(item.id.toString())
													// this.submitSuccessList.push(res.obj)
												}
												sessionStorage.setItem('fDeliveryID', this.orderList[0].fDeliveryID);
												this.payPageShow = true
												this.$router.replace({
													name: "PaymentOrder",
													params: {
														orderIds: paramsData,
														fBillTypeID: this.orderList[0].fBillTypeID,
														isTask:true,
														needObj:this.paymentOrderoptions
													}
												})
											} else {
												this.orderList.forEach((item, index) => {
													item.fCouponCustomerReceiveRecordID = "暂不使用";
												})
												// 如果失败则提示用户是否继续
												res.message = res.message.split(';');
												let createTipCode = res.message[1];
												let createMessage = res.message[0];
												this.$confirm(createMessage  + ', 是否继续?', '提示', {
													confirmButtonText: '确定',
													cancelButtonText: '取消',
													type: 'warning'
												}).then(() => {
													if (createTipCode == '001') {
														this.$store.dispatch("setShowAttestation", true);
													} else if (createTipCode == '002') {
														this.addInvoice('Add');
													} else if (createTipCode == '003') {
														if (this.getUserInfo.fCommerceTypeID == '1') {
															this.$router.push({
																path: '/businessme/individualUsersBindingCard',
																query: {
																	title: '开通账服通',
																	type: 2,
																	fAccountname: '账服通'
																}
															}) //跳转个人用户绑卡
														} else if (this.getUserInfo.fCommerceTypeID == '2') {
															this.$router.push({
																path: '/businessme/enterpriseUsersBindingCard',
																query: {
																	title: '开通账服通',
																	type: 2,
																	fAccountname: '账服通'
																}
															}) //跳转企业用户绑卡
														}
													} else if (createTipCode == '004') {
														this.submitListIndex = 0;
														this.submitList = []
														this.submitSuccessList = [];
													} else if (createTipCode == '005') {
														this.getCustomerPoint();
													} else if (createTipCode == '006') {
														this.submitListIndex = 0;
														this.submitList = []
														this.submitSuccessList = [];
													}
												}).catch(() => {});
											}
										},
										error => {
											this.createLoading = false;
										}
									);
							}else{
								//没开通的话，那就需要给他一个弹框让他
								this.dialogVisible = true
								
							}
						},
						error => {}
					);
					
				}else{
					// 提交参数
					this.submitList = [];
					// 提交成功数组
					this.submitSuccessList = [];
					this.orderList.forEach((item, index) => {
						if (item.fCouponCustomerReceiveRecordID == '暂不使用' || item.fCouponCustomerReceiveRecordID == '暂无可用') {
							item.fCouponCustomerReceiveRecordID = -1;
						}
						let params = {
							orderId: item.fOrderID,
							fBusinessTypeID: item.fBusinessTypeID,
							fGoodsID: item.fGoodsID,
							fAddressID: item.fAddressID,
							fUsePoint: item.fUsePoint,
							fDeliveryID: item.fDeliveryID,
							fCarrierID: item.fCarrierID,
							fCapTypeID:item.fCapTypeID,//资金类型id()
							fCouponCustomerReceiveRecordID: item.fCouponCustomerReceiveRecordID,
							fIsNeedFInvoiceStatus: item.fIsNeedFInvoiceStatus,
							fIsNeedInvoiceStatus: this.fIsNeedInvoiceStatus,
							fPlanAmount: item.fPlanAmount,
							fAppTypeID: this.fAppTypeID,
							frv:item.frv
						}
            if (this.tableData[index].length === 0) {
                    params.fRuleID = -1
                  } else {
                    params.fRuleID = this.tableData[index][0].fRuleID
                  }
						this.submitList.push(params);
					});
					console.log('this.orderIDList', this.orderIDList);
					this.createLoading = true;
					this.ApiRequestPutNOMess('api/mall/ebsale/order/update-b2c-new-batch', this.submitList)
						.then(
							res => {
								this.createLoading = false;
								if (res.message == "") {
									let paramsData = [];
									let orderIds = [];
									for (let item of this.orderIDList) {
										paramsData.push(item.id.toString())
										// this.submitSuccessList.push(res.obj)
									}
									sessionStorage.setItem('fDeliveryID', this.orderList[0].fDeliveryID);
									this.payPageShow = true
									this.$router.replace({
										name: "PaymentOrder",
										params: {
											orderIds: paramsData,
											fBillTypeID: this.orderList[0].fBillTypeID,
											isTask:true,
											needObj:this.paymentOrderoptions
											
										}
									})
								} else {
									this.orderList.forEach((item, index) => {
										item.fCouponCustomerReceiveRecordID = "暂不使用";
									})
									// 如果失败则提示用户是否继续
									res.message = res.message.split(';');
									let createTipCode = res.message[1];
									let createMessage = res.message[0];
									this.$confirm(createMessage  + ', 是否继续?', '提示', {
										confirmButtonText: '确定',
										cancelButtonText: '取消',
										type: 'warning'
									}).then(() => {
										if (createTipCode == '001') {
											this.$store.dispatch("setShowAttestation", true);
										} else if (createTipCode == '002') {
											this.addInvoice('Add');
										} else if (createTipCode == '003') {
											if (this.getUserInfo.fCommerceTypeID == '1') {
												this.$router.push({
													path: '/businessme/individualUsersBindingCard',
													query: {
														title: '开通账服通',
														type: 2,
														fAccountname: '账服通'
													}
												}) //跳转个人用户绑卡
											} else if (this.getUserInfo.fCommerceTypeID == '2') {
												this.$router.push({
													path: '/businessme/enterpriseUsersBindingCard',
													query: {
														title: '开通账服通',
														type: 2,
														fAccountname: '账服通'
													}
												}) //跳转企业用户绑卡
											}
										} else if (createTipCode == '004') {
											this.submitListIndex = 0;
											this.submitList = []
											this.submitSuccessList = [];
										} else if (createTipCode == '005') {
											this.getCustomerPoint();
										} else if (createTipCode == '006') {
											this.submitListIndex = 0;
											this.submitList = []
											this.submitSuccessList = [];
										}
									}).catch(() => {});
								}
							},
							error => {
								this.createLoading = false;
							}
						);
				}
				
			},


			//获取当前位置信息
			getMyAddress() {
				let _this = this;
				this.mapShow = true;

				this.$jsonp('https://apis.map.qq.com/ws/geocoder/v1/?address=' + this.addr +
						'&key=PM5BZ-2GP6S-7GHO5-6SIIX-DZD2S-RUB7S&output=jsonp', {})
					.then(json => {
						// alert(JSON.stringify(json.result.location));
						_this.maplat = json.result.location.lat;
						_this.maplng = json.result.location.lng;
						// 初始化地图,这里的1秒延迟是必要的,否则不能正常渲染出来 
						setTimeout(function() {
							_this.initMap()
						}, 1000);

					})
					.catch(err => {
						alert(JSON.stringify(err));
					});
			},
			//初始化地图
			initMap() {
				//定义map变量，调用 TMap.Map() 构造函数创建地图
				// "lat": 43.794399第一个参数
				// "lng": 87.631676,第二个参数
				var center = new TMap.LatLng(this.maplat, this.maplng); //设置中心点坐标
				//初始化地图
				var map = new TMap.Map(container, {
					center: center
				});
				/* 这个就是那个地图上的指针点 */
				//创建并初始化MultiMarker
				var markerLayer = new TMap.MultiMarker({
					map: map, //指定地图容器
					//样式定义
					styles: {
						//创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
						myStyle: new TMap.MarkerStyle({
							width: 25, // 点标记样式宽度（像素）
							height: 35, // 点标记样式高度（像素）
							//src: '../img/marker.png', //自定义图片路径
							//焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
							anchor: {
								x: 16,
								y: 32
							}
						})
					},
					//点标记数据数组
					geometries: [{
						id: '1', //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
						styleId: 'myStyle', //指定样式id
						position: new TMap.LatLng(this.maplat, this.maplng), //点标记坐标位置
						properties: {
							//自定义属性
							title: 'marker1'
						}
					}]
				});
			},
			//新增地址
			addAddress() {
				this.pageTitle = "新增";
				this.addVisible = true;
			},
			//编辑地址
			EditAddress(item) {
				this.pageTitle = "编辑";
				this.ApiRequestPostNOMess("api/mall/eb-customer/address/get", {
						id: item.fAddressID
					})
					.then(res => {
						res.obj.fIsDefault = res.obj.fIsDefault == 0 ? false : true
						res.obj.fFullPathAddress = res.obj.fFullPathAddress.replace(/\\/g, '');
						res.obj.area = ['', '', '', ''];
						res.obj.area[0] = res.obj.fGovernmenAreaID.substring(0, 2);
						res.obj.area[1] = res.obj.fGovernmenAreaID.substring(0, 4);
						res.obj.area[2] = res.obj.fGovernmenAreaID.substring(0, 6);
						res.obj.area[3] = res.obj.fGovernmenAreaID;
						this.addressForm = res.obj;
						this.addVisible = true;
					}, error => {});
			},
			//新增或者编辑地址
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						let areaN = this.addressForm.area;
						this.addressForm.fGovernmenAreaID = areaN[areaN.length - 1];
						if (this.pageTitle == "编辑") {
							this.addressForm.fIsDefault = this.addressForm.fIsDefault == true ? 1 : 0;
							this.addressForm.rowStatus = 16;
							this.ApiRequestPut('/api/mall/eb-customer/address/update', this.addressForm).then(
								result => {
									this.getProjectList();
									this.handleClose();
								},
								rej => {}
							);
						} else if (this.pageTitle == "新增") {
							this.ApiRequestPost('/api/mall/eb-customer/address/create', {
								fAddressTypeID: 1,
								fCustomerID: 0,
								feMail: this.addressForm.feMail,
								fGovernmenAreaID: this.addressForm.fGovernmenAreaID,
								fAddress: this.addressForm.fAddress,
								flng: 0,
								flat: 0,
								fLinkman: this.addressForm.fLinkman,
								fLinkPhone: this.addressForm.fLinkPhone,
								fRemark: "",
								fStatus: 1,
								fIsDefault: this.addressForm.fIsDefault == true ? 1 : 0,
								fProjectName: this.addressForm.fProjectName,
								fLastUseTime: "",
								fCreatorUserID: 0,
								rowStatus: 4
							}).then(
								result => {
									this.getProjectList();
									this.handleClose();
								},
								rej => {}
							);
						}
					} else {
						return false;
					}
				});
			},
			handleClose() {
				this.addVisible = false;
				this.resetForm();
			},
			//重置form表单
			resetForm() {
				this.addressForm = {
					fLinkman: '',
					fLinkPhone: '',
					area: [],
					fAddress: '',
					fProjectName: '',
					feMail: '',
					fAddressTypeID: '',
					fIsDefault: false
				}
			},
			//保存收件信息
			invoiceSubmitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						if (this.pageTitle == "新增") {
							this.addInvoiceForm.fAddressID = 0;
						}
						/* this.addInvoiceForm.fGovernmenAreaID = this.addInvoiceForm.area[this.addInvoiceForm.area.length-1];
						console.log(this.addInvoiceForm); */
						this.ApiRequestPost('/api/mall/eb-customer/address/create-invoice-address', {
							fAddressID: this.addInvoiceForm.fAddressID,
							feMail: this.addInvoiceForm.feMail,
							fGovernmenAreaID: this.addInvoiceForm.area[this.addInvoiceForm.area.length - 1],
							fAddress: this.addInvoiceForm.fAddress,
							fLinkman: this.addInvoiceForm.fLinkman,
							fLinkPhone: this.addInvoiceForm.fLinkPhone
						}).then(
							result => {
								console.log(result);
								this.getInvoiceInfo();
								this.addInvoiceStatus = false
							},
							rej => {
								this.addInvoiceStatus = false
							}
						);
					} else {
						return false;
					}
				});
			},
			// 新增弹窗提交成功回调
			myMapTypeChange(val) {
			  this.myMapTypeVisible = val;
			},
			// 显示地图
			handleClick(row) {
			  this.myMapTypeID= row.fAreaID
			  this.myMapTypeVisible=true;
			},
    // 获取限时奖励信息
    getexpressInfo() {
      this.isShowExpress = false
      // if (this.orderList[0].fCapTypeID === 2) {
      if (this.orderList[0].fTradingModelID == 1) {
        for (let i = 0; i < this.getexpress.length; i++) {
          this.ApiRequestPostNOMess('api/mall/ebactivity/limited-delivery-rule/get-limited-delivery-rule-B2C', this.getexpress[i]).then(res => {
            if (res.obj.length == 0) {
              this.isShowExpress = false 
              this.tableData[i] = [
                // {
                //   activityTime: '',
                //   fDeliveryNames: '',
                //   fMaxMins: '',
                //   fFence: '',
                //   activeActivity: ''
                // },
              ]
            return
            } else {
              this.isShowExpress = true
              this.loadingactivity = true
              this.tableDatas[i] = res.obj
              const arr = this.tableDatas[i].filter(item => item.fRuleID == this.radioIdSelects[i])
              this.tableDatas[i].forEach(item => {
                item.activityTime = this.getTime(item.fBeginTime)   + '至' + this.getTime(item.fEndTime) 
                item.fBeginTime = this.getTime(item.fBeginTime) 
              item.fEndTime =  this.getTime(item.fEndTime)
                item.integral = item.fPointAmount + '积分/' + this.fUnitNames[i]
              })
              console.log(arr)
              this.tableData[i] = arr
              if (arr.length===0) {
              this.ishsowTable = false
              this.radioIdSelect = -1
            } else {
              this.ishsowTable = true
            }
            this.tableData[i] = arr
            this.loadingactivity = false
            }
          })
        }
      } else {
        for (let i = 0; i < this.getexpress.length; i++) {
        this.ApiRequestPostNOMess('api/mall/ebactivity/limited-delivery-rule/get-limited-delivery-rule-B2B', this.getexpress[i]).then(res => {
          if (res.length == 0) {
            this.isShowExpress = false 
            this.tableData[i] = []
            this.ishsowTable = false
            return
          } else {
            this.isShowExpress = true
            this.loadingactivity = true
            this.tableDatas[i] = res
            const arr = this.tableDatas[i].filter(item => item.fRuleID === this.radioIdSelects[i])
            this.tableDatas[i].forEach(item => {
              item.activityTime = this.getTime(item.fBeginTime)   + '至' + this.getTime(item.fEndTime) 
              item.integral = item.fPointAmount + '积分/' + this.fUnitNames[i]
            })
            if (arr.length===0) {
              this.ishsowTable = false
              this.radioIdSelect = -1
            } else {
              this.ishsowTable = true
            }
			arr.map(x=>{
				if(x.fIsHd ==1){
				 x.fIsHdName='汉德设备'
				}else if(x.fIsHd ==0){
				  x.fIsHdName='GPS设备'
				} 
			})
            this.tableData[i] = arr
            this.loadingactivity = false
          }
        })
      }
    }
    },
    showActivity(index) {
      this.index = index
      if (this.num === 0) {
        this.radioIdSelect = this.radioIdSelects[index]
      }
      this.num++
      this.activitys = this.tableDatas[index]
	  this.activitys.map(item=>{
	  		 if(item.fIsHd ==1){
	  		   item.fIsHdName='汉德设备'
	  		 }else if(item.fIsHd ==0){
	  		   item.fIsHdName='GPS设备'
	  		 } 
	  })
      this.isShowActivityDailog = true
    },
    handleSelectionChange(val) {
      this.radioActivity = val
      console.log(this.radioActivity)
      this.radioIdSelects[0] = this.radioActivity.fRuleID
    },
    clickRadio(e, row) {
        if(e.target.tagName != 'INPUT'){
          return
      }
        if (this.radioIdSelect === row.fRuleID) {
          this.radioIdSelect = -1
          this.tableData[this.index] = []
          this.index = -1
        }
    },
    // 确认选中
    checkedActivity() {
        if (this.radioIdSelect === -1) {
          this.$message.warning('请选择一个活动奖励')
          return
        }
        this.tableData[this.index] = [this.radioActivity]
        console.log(this.tableData[this.index])
        this.isShowActivityDailog = false
        this.ishsowTable = true
      },
      // 取消选中
      uncheckedActivity() {
        this.radioIdSelect = -1
        this.radioIdSelects[0] = -1
        this.tableData[this.index] = []
        this.isShowActivityDailog = false
        this.ishsowTable = false
      },
    },
		
    
	};
</script>

<style lang="scss" scoped>
  .myMap {
    /deep/.el-dialog__body {
      padding: 0;
    }
  }
  .tit {
    display: inline-block;
	color:#ff0000
  }
  .btn {
    color: #0173FE;
    cursor: pointer;
    display: inline-block;
  }

	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed('themes_color');
			color: themed('text_color_white');
		}
	}

	.border-color-theme {
		@include themify($themes) {
			border: 1px solid themed('themes_color');
		}
	}

	.pointer-border-color-theme:hover {
		cursor: pointer;

		@include themify($themes) {
			border: 1px solid themed('themes_color');
		}
	}

	.pointer-color-theme:hover {
		cursor: pointer;

		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.border-bottom-theme {
		@include themify($themes) {
			border-bottom: 1px solid themed('themes_color');
		}
	}

	/* 面包屑CSS */
	// .el-tabs--card .el-tabs__header {
	// 	border-bottom: none;
	// }
	.breadcrumb {
		margin-top: 15px;
		background-color: white;
	}

	// .breadcrumb .el-breadcrumb {
	// 	width: 1225px;
	// 	line-height: 30px;
	// 	font-size: 16px;
	// 	margin: 0 auto;
	// }
	/* 面包屑CSS END */

	::v-deep .el-checkbox {
		padding: 0 10px !important;
	}

	// 购物车
	.wrap {
		width: 100%;
		// margin-bottom: 40px;
		// margin-top: 9px;
	}

	// 下方按钮
	/* 新的购物车样式 */

	.tag-box {
		padding: 0 3px;
		background-image: linear-gradient(to right top, #FF0000, #FF9999);
	}

	.tag-box-red {
		// margin: 0px !important;
		margin-right: 8px;
		padding: 0 4px;
		// height: 40px;
		line-height: 18px;
		color: #ffffff;
		background-image: linear-gradient(to right, #ff0000, #ff9999);
		// border-radius: 4px;
		font-weight: 400;
	}

	.tag-box-blue {
		// margin: 0px !important;
		padding: 0 5px;
		// height: 19px;
		line-height: 18px;
		color: #ffffff;
		font-weight: 400;
	}

	.margin-l-17 {
		margin-left: 17px;
	}

	.imageRadius {
		width: 65px;
		height: 65px;
		border-radius: 10px;
	}

	.width756 {
		width: 756px;
	}

	.width156 {
		width: 156px;
	}

	.bottomBox {
		height: 80px;
		background: #f8f9fe;
		width: 100%;
	}

	.flexUD {
		display: flex;
		//align-items: center; /*垂直居中*/
		justify-content: center;
		/*水平居中*/
	}

	/* 单选地址 */
	.price-box.active {
		width: 1070px;
		height: 50px;

		margin: 15px;
		position: relative;
		// transition: all 0.5s ease;
		// &::after {
		// 	content: '✔';
		// 	display: block;
		// 	height: 0px;
		// 	width: 0px;
		// 	position: absolute;
		// 	bottom: 0;
		// 	right: 0;
		// 	color: #fff;
		// 	font-size: 14px;
		// 	line-height: 18px;
		// 	border: 18px solid;
		// 	// border-color: transparent #55aaff #55aaff transparent;
		// 	@include themify($themes) {
		// 		border-color: transparent themed('themes_color') themed('themes_color') transparent;
		// 	}
		// }
	}

	.price-box.none {
		width: 1070px;
		height: 50px;
		margin: 15px;
		position: relative;
		// border: 2px solid #dfdfdf;
	}

	/* 步骤条 */
	::v-deep .el-step__line-inner {
		height: 10px;
		background-color: #00cc88;
	}

	::v-deep .el-step__icon.is-text {
		background: #00cc88;
		color: #fff;
		border: 5px solid;
	}

	::v-deep .el-step__icon {
		height: 50px;
		width: 50px;
	}

	::v-deep .el-step.is-horizontal .el-step__line {
		height: 10px;
		background: #e4e4e4;
		margin-top: 8px;
	}

	::v-deep .is-success {
		border-color: #dffff5;
		color: #00cc88;

		.el-step__icon.is-text {
			background: #00cc88;
			color: #dffff5;
		}
	}

	::v-deep .is-process {
		border-color: #fafafa;
		color: #999;

		.el-step__icon.is-text {
			background: #e4e4e4;
			color: #fafafa;
		}
	}

	::v-deep .is-wait {
		border-color: #fafafa;
		color: #999;

		.el-step__icon.is-text {
			background: #e4e4e4;
			color: #fafafa;
		}
	}

	::v-deep .el-step__title.is-process {
		font-weight: 400;
		// color: #999999;
	}

	::v-deep .el-step__title.is-wait {
		font-weight: 400;
		// color: #999999;
	}

	/* 步骤条结束 */

	.margin-l-15 {
		margin-left: 15px;
	}

	.margin-lr-15 {
		margin-left: 15px;
		margin-right: 15px;
	}

	/* 选择框 */
	::v-deep .el-checkbox {
		padding: 0 15px !important;
	}

	/* 下拉 */
	::v-deep .border-box .el-input__inner {
		border-radius: 0;
		width: 140px;
		// height: 32px;
		font-size: 14px;
		text-align: center;

		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	::v-deep .el-dialog .el-input__inner {
		width: 320px;
	}

	.inputStyle {
		border-radius: 0;
		width: 140px;
		height: 28px;
		font-size: 14px;
		text-align: center;

		@include themify($themes) {
			color: themed('themes_color');
		}

		border: 1px solid #f2f2f2;
	}

	/* 承运商 */
	.carrier {
		// width: 100%;
	}

	::v-deep .carrier .el-input__inner {
		width: 255px;
	}

	::v-deep .invoice .el-input__inner {
		width: 321px;
	}

	::v-deep .el-cascader {
		width: 321px;
	}

	/* 虚线 */
	.divcss5-3 {
		border-top: 2px dashed #f2f2f2;
		// height: 50px;
		width: 100%;
		margin-top: 10px;
	}

	.hover:hover {
		text-decoration: underline;

		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
	.el-checkbox__input.is-indeterminate .el-checkbox__inner {
		@include themify($themes) {
			background-color: themed('themes_color');
			border-color: themed('themes_color');
		}
	}

	::v-deep .el-dialog__body {
		/* padding: 10px 20px 20px 20px; */
    padding: 30px 20px;
	}
	.orderDialog{
		::v-deep .el-dialog__body {
				padding: 10px 20px 20px 20px;
		}
	}
	
	.form-label-mark {
		width: 12px;
		height: 12px;
		border-radius: 50%;
		border: 1px solid #ccc;
		font-size: 12px;
		color: #ccc;
		cursor: pointer;
		margin-right: 10px;
	}
	.OrderText{
		line-height: 35px;
		color: #FF3333;
	}
	a:hover{
		border-bottom: 1px solid #0173FE;
	}
</style>
